<template>
  <div class="typeSelectionContainer">
    <transition name="fade">
      <div
        class="defaultDisplayArea"
        v-if="!unfoldFlag && selectedCategorys.length == 0"
      >
        <div class="displayBox">
          <div
            class="typeItemBox"
            v-for="item in recommendCategorys"
            :key="item.id"
            :class="item.isSelected ? 'selectedStyle' : ''"
            @click="selectedRecommend(item)"
          >
            {{ item.name }}
          </div>
          <div class="placeholder" @click.stop="unfoldFlag = !unfoldFlag">
            ...
          </div>
        </div>
        <template v-if="showRecommendClearButton">
          <div class="clearButton" @click="clearRecommendSelection">
            <span class="iconfont_Me icon-qingchu iconStyle"></span>
          </div>
        </template>
        <template v-else>
          <div class="isolationLine"></div>
          <div class="operationButton" @click="unfoldFlag = !unfoldFlag">
            <span class="iconfont_Me icon-class iconStyle"></span>
          </div>
        </template>
      </div>
    </transition>
    <transition name="fade">
      <div
        class="defaultDisplayArea"
        v-if="!unfoldFlag && selectedCategorys.length != 0"
      >
        <div class="displayBox">
          <div
            class="typeItemBox selectedStyle"
            v-for="item in selectedCategorys"
            :key="item.id"
          >
            {{ item.name }}
          </div>
          <div class="placeholder" @click.stop="unfoldFlag = !unfoldFlag">
            ...
          </div>
        </div>
        <template>
          <div class="clearButton" @click="clearSelected">
            <span class="iconfont_Me icon-qingchu iconStyle"></span>
          </div>
        </template>
      </div>
    </transition>
    <transition name="fade">
      <div class="unfoldDisplayArea" v-if="unfoldFlag">
        <div class="displayBox">
          <div class="close" @click.stop="unfoldFlag = false">
            <span class="iconfont_Me icon-x iconStyle"></span>
          </div>
          <div
            class="typeItemBox"
            v-for="(item, index) in categorys"
            :class="item.isSelected ? 'selectedStyle' : ''"
            :key="item.id"
            @click="selectedType(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="footerBox">
          <el-tooltip :content="$t('clear')" placement="bottom">
            <div class="buttonStyle leftButton" @click="clearSelection">
              <span class="iconfont_Me icon-qingchu iconStyle"></span>
            </div>
          </el-tooltip>
          <div class="buttonStyle rightButton" @click="confirmSelection">
            <span class="iconfont_Me icon-check iconStyle"></span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { getRecommendCategories } from "@/api/newVersion/homePage";
import { getCategories } from "@/api/newVersion/pageHeader";
export default {
  name: "PostTypeSelection",
  data() {
    return {
      // 展开状态
      unfoldFlag: false,
      // 所有帖子类型
      categorys: [],
      // 选中的帖子类型
      selectedCategorys: [],
      // 系统推荐的默认类型
      recommendCategorys: [],
    };
  },
  props: {
    isScrollUp: {
      type: Boolean,
      default: true,
    },
    setDefault: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    // 展示推荐模块中的清除已选中按钮
    showRecommendClearButton() {
      return this.recommendCategorys.some((i) => i.isSelected);
    },
  },
  watch: {
    // 监听主页列表的滚动状态
    isScrollUp: {
      handler(val, old) {
        if (val != old && !val) {
          this.clearSelection();
          this.unfoldFlag = false;
          this.$forceUpdate();
        }
      },
      deep: true,
    },
    // 监听选中框打开的变化
    unfoldFlag: {
      handler(val, old) {
        if (val && val != old) {
          this.categorys = this.categorys.map((e) => {
            if (
              this.selectedCategorys.some((item) => item.id == e.id) ||
              this.recommendCategorys.some(
                (item) => item.id == e.id && item.isSelected
              )
            ) {
              e.isSelected = true;
            } else {
              e.isSelected = false;
            }
            return e;
          });
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.handlingGetCategories();
    let strongNav = window.localStorage.getItem("catchNavs");
    // 获取本地菜单
    if (this.setDefault.length == 0) {
      this.categorys = JSON.parse(strongNav);
      this.categorys.shift();
      this.categorys = this.categorys.map((e) => {
        e.isSelected = false;
        return e;
      });
      await this.getRecommendCategories();
    } else {
      const categorys = JSON.parse(strongNav).filter((item) => {
        return this.setDefault.includes(item.id);
      });
      this.categorys = categorys.map((e) => {
        e.isSelected = false;
        return e;
      });
      this.recommendCategorys = _.cloneDeep(this.categorys).slice(0, 5);
    }
  },
  methods: {
    // 从后台获取类型列表，这里不能从本地记录里面拿，因为很header组件里的异步请求有可能还没有请求到数据，会拿到已经储存在本地的类型数据
    handlingGetCategories() {
      return new Promise(async (resolve, reject) => {
        let result = await getCategories();
        if (result.code == 200) {
          let navs = result.data.data;
          navs.unshift({
            category_id: 0,
            category_name: this.$t("all"),
          });
          let catchNavs = [];
          navs.map((element) => {
            let item = {
              id: element.category_id,
              name: element.category_name,
            };
            catchNavs.push(item);
          });
          window.localStorage.setItem("catchNavs", JSON.stringify(catchNavs));
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        resolve("success");
      });
    },
    // 获取推荐类型列表
    getRecommendCategories() {
      return new Promise(async (resolve, reject) => {
        let result = await getRecommendCategories();
        if (result.code == 200) {
          let catchNavs = [];
          result.data.data.map((element) => {
            let item = {
              id: element.categoryId,
              name: element.categoryName,
              isSelected: false,
            };
            catchNavs.push(item);
          });
          this.recommendCategorys = catchNavs;
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        resolve("success");
      });
    },
    // 清除已被选中的类型数据
    clearSelected() {
      this.selectedCategorys = [];
      this.sendRequest(this.selectedCategorys);
      this.$forceUpdate();
    },
    // 清除选中的类型
    clearSelection() {
      this.categorys = this.categorys.map((e) => {
        e.isSelected = false;
        return e;
      });
    },
    // 直接从推荐类型里选择类型
    selectedRecommend(item) {
      this.recommendCategorys = this.recommendCategorys.map((recommend) => {
        if (recommend.id == item.id) {
          recommend.isSelected = !recommend.isSelected;
        }
        return recommend;
      });
      this.sendRequest(
        this.recommendCategorys.filter((recommend) => {
          return recommend.isSelected;
        })
      );
    },
    // 清除推荐
    clearRecommendSelection() {
      this.recommendCategorys = this.recommendCategorys.map((recommend) => {
        recommend.isSelected = false;
        return recommend;
      });
      this.sendRequest(
        this.recommendCategorys.filter((recommend) => {
          return recommend.isSelected;
        })
      );
    },
    // 确认选中的类型
    confirmSelection() {
      this.selectedCategorys = this.categorys.filter((e) => e.isSelected);
      this.unfoldFlag = false;
      this.recommendCategorys = this.recommendCategorys.map((recommend) => {
        recommend.isSelected = false;
        return recommend;
      });
      this.sendRequest(this.selectedCategorys);
      this.$forceUpdate();
    },
    // 选择这个类型
    selectedType(item) {
      this.categorys = this.categorys.map((el) => {
        if (el.id == item.id) {
          el.isSelected = !el.isSelected;
        }
        return el;
      });
      this.$forceUpdate();
    },
    // 发送请求
    sendRequest(val) {
      let searchCategories = "";
      for (let i = 0; i < val.length; i++) {
        // 判断是否被选中状态，并且不是全部按钮
        if (val[i].isSelected) {
          searchCategories += val[i].id + ",";
        }
      }
      searchCategories = searchCategories.slice(0, searchCategories.length - 1);
      this.$emit("confirm", searchCategories);
    },
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s, transform .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform translate3d(0,-50px,0);
}
.typeSelectionContainer
  width 100%;
  position relative;
  z-index 99;
  .defaultDisplayArea
    width 100%;
    height 50px;
    cursor pointer;
    overflow hidden;
    display flex;
    flex-flow row;
    align-items center;
    justify-content flex-start;
    background #FFFFFF;
    .displayBox
      flex 1;
      height 50px;
      min-width 0;
      height 100%;
      padding 0 20px 0 40px;
      line-height 50px;
      text-align left;
      overflow hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      .placeholder
        min-width: 0;
        font-size: 22px;
        display inline-block;
      .typeItemBox
        padding 0 15px;
        border-radius 6px;
        display inline-block;
        height 30px;
        line-height 30px;
        border 1px solid #666666;
        font-size 14px;
        color #333333;
        margin-right 10px;
        user-select none;
        transition color .3s,background .3s,border .3s;
      .selectedStyle
        border 1px solid #33CC66 !important;
        background #F2FCF8 !important;
        color #33CC66;
    .isolationLine
      height 14px;
      width 1px;
      flex-shrink 0;
      background #CCCCCC;
    .operationButton
      width 24px;
      height 24px;
      flex-shrink 0;
      margin 0 40px 0 10px;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        font-size 20px;
        color #333333;
    .clearButton
      width 30px;
      height 30px;
      flex-shrink 0;
      margin 0 40px 0 10px;
      display flex;
      align-items center;
      justify-content center;
      background #FFFFFF;
      .iconStyle
        font-size 24px;
        color #666666;
  .unfoldDisplayArea
    top 0;
    left 0;
    z-index 2;
    width 100%;
    min-height 0;
    padding 20px 40px;
    background #FFFFFF;
    overflow hidden;
    position absolute;
    .displayBox
      min-height 0;
      width 100%;
      height 100%;
      text-align left;
      overflow hidden;
      white-space: wrap;
      line-height 36px;
      position: relative;
      .close
        text-align: right;
        .iconStyle
          font-size: 16px;
          cursor: pointer;
      .typeItemBox
        padding 0 10px;
        border-radius 6px;
        display inline-block;
        height 30px;
        line-height 30px;
        border 1px solid #666666;
        font-size 14px;
        color #333333;
        margin-right 10px;
        user-select none;
        cursor pointer;
        transition color .3s,background .3s,border .3s;
      .selectedStyle
        border 1px solid #33CC66 !important;
        background #F2FCF8 !important;
        color #33CC66;
    .footerBox
      height 40px;
      width 100%;
      margin-top 20px;
      background transparent;
      display flex;
      align-items center;
      justify-content center;
      .buttonStyle
        height 40px;
        cursor pointer;
        border-radius 4px;
        display flex;
        align-items center;
        justify-content center;
        .iconStyle
          font-size 22px;
      .leftButton
        width 60px;
        background #F0F0F0;
        margin-right 10px;
        .iconStyle
          color #666666
      .rightButton
        width 160px;
        background #33CC66;
        .iconStyle
          color #FFFFFF
</style>
