var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "personalInfoContent" },
    [
      _c("PersonalInformation", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentTabKey === "personalInfo",
            expression: "currentTabKey === 'personalInfo'"
          }
        ],
        attrs: { personalInfo: _vm.personalInfo }
      }),
      _c("GroupInformation", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentTabKey === "groupInfo",
            expression: "currentTabKey === 'groupInfo'"
          }
        ],
        ref: "groupInformation",
        attrs: {
          currentTabKey: _vm.currentTabKey,
          personalInfo: _vm.personalInfo
        }
      }),
      _c("PostInformation", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentTabKey === "postInfo",
            expression: "currentTabKey === 'postInfo'"
          }
        ],
        ref: "postInformation",
        attrs: {
          currentTabKey: _vm.currentTabKey,
          personalInfo: _vm.personalInfo
        }
      }),
      _c("FavoritesList", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentTabKey === "collection",
            expression: "currentTabKey === 'collection'"
          }
        ],
        ref: "favoritesList",
        attrs: {
          currentTabKey: _vm.currentTabKey,
          personalInfo: _vm.personalInfo
        }
      }),
      _c("DeviceInformation", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentTabKey === "deviceInfo",
            expression: "currentTabKey === 'deviceInfo'"
          }
        ],
        ref: "deviceInformation",
        attrs: {
          currentTabKey: _vm.currentTabKey,
          personalInfo: _vm.personalInfo
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }