var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        width: "286",
        placement: "bottom",
        trigger: "hover",
        offset: -105,
        "visible-arrow": false,
        "popper-class": "favoritesPopover"
      }
    },
    [
      _c("FavoritesHeader", { on: { addNewFavorites: _vm.addNewFavorites } }),
      _c("div", { staticClass: "headerIntervalLine" }),
      _vm.favoritesList.length === 0
        ? _c(
            "div",
            { staticClass: "noDataContent" },
            [_c("PlaceholderMap", { attrs: { placeholderType: 2 } })],
            1
          )
        : _vm._e(),
      _vm.favoritesList.length !== 0
        ? _c(
            "div",
            { staticClass: "favoritesListContent" },
            [
              _c(
                "div",
                {
                  staticClass: "favoritesItem",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.selectThisFavorites(-1)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "favoritesLeft" }, [
                    _c("div", { staticClass: "favoritesName" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("allCollections")) +
                          "\n        "
                      )
                    ]),
                    _c("div", { staticClass: "favoritesCount" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(`(${_vm.favoritesCount})`) +
                          "\n        "
                      )
                    ])
                  ])
                ]
              ),
              _vm._l(_vm.favoritesList, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "favoritesItem",
                    attrs: { title: item.name },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.selectThisFavorites(index)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "favoritesLeft" }, [
                      _c("div", { staticClass: "favoritesName" }, [
                        _vm._v(
                          "\n          " + _vm._s(item.name) + "\n        "
                        )
                      ]),
                      _c("div", { staticClass: "favoritesCount" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(`(${item.count})`) +
                            "\n        "
                        )
                      ])
                    ]),
                    _c(
                      "CommonPopover",
                      {
                        attrs: {
                          operatingItem: item,
                          operatingMenu: _vm.operatingMenu
                        },
                        on: { selectThisMenuItem: _vm.selectThisMenuItem }
                      },
                      [
                        _c("div", { staticClass: "favoritesRight" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/images/newPersonalCenter/favoritesOperating.png")
                            }
                          })
                        ])
                      ]
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm._t("default", null, { slot: "reference" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }