<template>
  <div class="wrapBox" v-loading="dataLoading">
    <div class="favoritesHeader">
      <span class="favoritesTitle">
        {{ favoritesName }}&nbsp;&nbsp;({{ favoritesShowCount }})
      </span>
      <FavoritesPopover
        :favoritesList="favoritesList"
        @selectThisFavorites="selectThisFavorites"
      >
        <div class="favoritesIcon">
          <img
            class="iconImage"
            :src="
              require('../../../../../assets/images/newPersonalCenter/favoritesIcon.png')
            "
          />
        </div>
      </FavoritesPopover>
    </div>
    <div
      class="favoritesItem"
      v-for="(favoritesItem, index) in collectList"
      :key="favoritesItem.collectId"
    >
      <PostInformationItem
        v-if="favoritesItem.type === '1'"
        :postInfo="favoritesItem"
        :operatingMenu="operatingMenu"
        :fromType="'favorites'"
      >
      </PostInformationItem>
    </div>
    <div class="noDataBox" v-if="collectList.length === 0">
      <PlaceholderMap :placeholderType="2"></PlaceholderMap>
    </div>
  </div>
</template>
<script>
import PlaceholderMap from "../../../../components/placeholderMap";
import FavoritesPopover from "../../../../components/favorites/favoritesPopover";
import PostInformationItem from "../../../../components/homeDisplayComponents/postInformationItem";
import {
  getFavoritesListData,
  getCollectList,
  startUnfavorite,
} from "@/api/newVersion/interfaceForFavorites";
export default {
  name: "favoritesList",
  components: {
    PlaceholderMap,
    FavoritesPopover,
    PostInformationItem,
  },
  data() {
    return {
      // 数据加载loading
      dataLoading: false,
      // 收藏夹列表
      favoritesList: [],
      // 当前展示的收藏夹名称
      favoritesName: this.$t("allCollections"),
      // 收藏夹展示的总数
      favoritesShowCount: "0",
      // 收藏夹ID
      bookmarkId: "",
      // 收藏数据列表
      collectList: [],
      // 收藏数据展示页码
      pageNum: 0,
      // 操作菜单
      operatingMenu: [
        {
          imageSrc: require("../../../../../assets/images/favorites/favorited.png"),
          imageWidth: 14,
          imageHeight: 13,
          operatingFn: this.submitUnfavoriteInfo,
          tooltipContent: this.$t("collection"),
        },
        {
          imageSrc: require("../../../../../assets/images/favorites/transferSmartIcon.png"),
          imageWidth: 14,
          imageHeight: 14,
          operatingFn: this.transferFavorites,
          tooltipContent: this.$t("move"),
        },
      ],
    };
  },
  props: {
    // 用户的详细信息
    personalInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentTabKey: {
      type: String,
      default: "personalInfo",
    },
  },
  watch: {
    // 监听是否需要加载群列表
    currentTabKey: {
      async handler(val, old) {
        if (val === "collection" && val !== old) {
          await this.getFavoritesData();
        }
      },
      deep: true,
    },
    // 监听是否需要刷新收藏夹列表
    "$store.state.favorites.needRefreshFavoritesList": {
      async handler(val, old) {
        if (val && val !== old) {
          await this.getFavoritesListData();
          this.$store.commit("setNeedRefreshFavoritesList", false);
        }
      },
      deep: true,
    },
  },
  methods: {
    // 取消收藏
    async submitUnfavoriteInfo(itemInfo) {
      let result = await startUnfavorite(itemInfo.dataId);
      if (result.code == 200) {
        this.collectList = this.collectList.filter((element) => {
          return element.collectId !== itemInfo.collectId;
        });
        this.$forceUpdate();
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 转移收藏夹
    transferFavorites(itemInfo) {
      if (itemInfo.type === "1") {
        itemInfo.favoritesType = "post";
      }
      // 将当前基本信息存入收藏状态管理器
      this.$store.commit("setNeedToOperatObjectInformation", itemInfo);
      // 这一步是展示选择收藏夹组件
      this.$store.commit("setNeedToShowInDialog", "transferFavoritesBlock");
      // 打开收藏操作弹窗
      this.$store.commit("setFavoritesDialogStatus", true);
    },
    // 获取收藏相关数据
    async getFavoritesData() {
      await this.getFavoritesListData();
      this.pageNum = 1;
      this.collectList = [];
      await this.getCollectionListData();
    },
    // 获取收藏夹列表
    getFavoritesListData() {
      return new Promise(async (resolve, reject) => {
        // 获取收藏夹列表
        let result = await getFavoritesListData();
        if (result.code == 200) {
          this.favoritesList = result.data.data;
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        resolve("success");
      });
    },
    // 加载更多数据
    loadMoreData() {
      return new Promise(async (resolve, reject) => {
        this.pageNum++;
        await this.getCollectionListData();
        resolve("success");
      });
    },
    // 获取收藏数据列表
    getCollectionListData() {
      return new Promise(async (resolve, reject) => {
        this.dataLoading = true;
        let params = {
          pageSize: 10,
          page: this.pageNum,
          bookmarkId: this.bookmarkId,
        };
        let result = await getCollectList(params);
        if (result.code == 200) {
          // 获取当前展示的收藏夹里的收藏数据数量
          this.favoritesShowCount = result.data.total;
          if (result.data.data.length !== 0) {
            // 当时请求的第一页的时候，清空列表数据
            if (this.pageNum === 1) {
              this.collectList = [];
            }
            this.collectList = this.collectList.concat(result.data.data);
            // 过滤一次防止有相同的数据出现
            this.collectList = this.filterAnArrayOfObjects(
              this.collectList,
              "collectId"
            );
          } else if (this.pageNum === 1) {
            this.collectList = [];
          } else if (this.pageNum > 1) {
            this.pageNum--;
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        this.dataLoading = false;
        this.$nextTick(() => {
          resolve("success");
        });
      });
    },
    // 选中的收藏夹
    async selectThisFavorites(params) {
      this.favoritesName = params.favoritesName;
      this.bookmarkId = params.bookmarkId;
      this.pageNum = 1;
      this.collectList = [];
      await this.getCollectionListData();
    },
  },
};
</script>
<style lang="stylus" scoped>
.wrapBox {
  width: 100%;
  min-height: 0;
  overflow: hidden;

  .favoritesHeader {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 0 34px 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F0F0F0;

    .favoritesTitle {
      font-size: 14px;
      color: #343434;
      text-align: left;
    }

    .favoritesIcon {
      width: 16px;
      height: 15px;
      cursor: pointer;

      .iconImage {
        width: 16px;
        height: 15px;
      }
    }
  }

  .favoritesItem {
    width: 100%;
    min-height: 0px;
    overflow: hidden;
    background: #FFFFFF;
    margin-bottom: 10px;
  }

  .noDataBox {
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
