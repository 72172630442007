var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "typeSelectionContainer" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.unfoldFlag && _vm.selectedCategorys.length == 0
          ? _c(
              "div",
              { staticClass: "defaultDisplayArea" },
              [
                _c(
                  "div",
                  { staticClass: "displayBox" },
                  [
                    _vm._l(_vm.recommendCategorys, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "typeItemBox",
                          class: item.isSelected ? "selectedStyle" : "",
                          on: {
                            click: function($event) {
                              return _vm.selectedRecommend(item)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.name) + "\n        "
                          )
                        ]
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "placeholder",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.unfoldFlag = !_vm.unfoldFlag
                          }
                        }
                      },
                      [_vm._v("\n          ...\n        ")]
                    )
                  ],
                  2
                ),
                _vm.showRecommendClearButton
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "clearButton",
                          on: { click: _vm.clearRecommendSelection }
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me icon-qingchu iconStyle"
                          })
                        ]
                      )
                    ]
                  : [
                      _c("div", { staticClass: "isolationLine" }),
                      _c(
                        "div",
                        {
                          staticClass: "operationButton",
                          on: {
                            click: function($event) {
                              _vm.unfoldFlag = !_vm.unfoldFlag
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me icon-class iconStyle"
                          })
                        ]
                      )
                    ]
              ],
              2
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.unfoldFlag && _vm.selectedCategorys.length != 0
          ? _c(
              "div",
              { staticClass: "defaultDisplayArea" },
              [
                _c(
                  "div",
                  { staticClass: "displayBox" },
                  [
                    _vm._l(_vm.selectedCategorys, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "typeItemBox selectedStyle"
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.name) + "\n        "
                          )
                        ]
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "placeholder",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.unfoldFlag = !_vm.unfoldFlag
                          }
                        }
                      },
                      [_vm._v("\n          ...\n        ")]
                    )
                  ],
                  2
                ),
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearButton",
                      on: { click: _vm.clearSelected }
                    },
                    [
                      _c("span", {
                        staticClass: "iconfont_Me icon-qingchu iconStyle"
                      })
                    ]
                  )
                ]
              ],
              2
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.unfoldFlag
          ? _c("div", { staticClass: "unfoldDisplayArea" }, [
              _c(
                "div",
                { staticClass: "displayBox" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "close",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.unfoldFlag = false
                        }
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "iconfont_Me icon-x iconStyle"
                      })
                    ]
                  ),
                  _vm._l(_vm.categorys, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "typeItemBox",
                        class: item.isSelected ? "selectedStyle" : "",
                        on: {
                          click: function($event) {
                            return _vm.selectedType(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.name) + "\n        "
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "footerBox" },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: { content: _vm.$t("clear"), placement: "bottom" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "buttonStyle leftButton",
                          on: { click: _vm.clearSelection }
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me icon-qingchu iconStyle"
                          })
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "buttonStyle rightButton",
                      on: { click: _vm.confirmSelection }
                    },
                    [
                      _c("span", {
                        staticClass: "iconfont_Me icon-check iconStyle"
                      })
                    ]
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }