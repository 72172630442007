<template>
  <div class="wrapBox">
    <div class="deviceListBox" v-if="devicesOptions.length > 0">
      <div class="checkBoxArea">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group
          v-model="checkedDevices"
          @change="handleCheckedCitiesChange"
          class="checkGroupArea"
        >
          <el-checkbox
            v-for="(device, index) in devicesOptions"
            :label="device.deviceNo"
            :key="device.deviceNo"
          >
            {{ `设备${index + 1}` }}
            <img
              :src="
                require(device.deviceType == 'web'
                  ? '../../../../../assets/images/newPersonalCenter/webIcon.png'
                  : '../../../../../assets/images/newPersonalCenter/phoneIcon.png')
              "
              :style="{
                width: device.deviceType == 'web' ? '18px' : 'auto',
                height: device.deviceType == 'web' ? 'auto' : '16px',
                verticalAlign: 'middle',
              }"
            />
            <span :style="{ color: device.freezeStatus ? 'red' : '' }">
              {{ device.freezeStatus ? "已禁用" : "" }}
            </span>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="operationArea">
        <div
          class="disabledButton commonStyle"
          :class="{ prohibitClicking: checkedDevices.length == 0 }"
          v-waves
          @click="disabledDevice"
        >
          禁用
        </div>
        <div
          class="undisableButton commonStyle"
          :class="{ prohibitClicking: checkedDevices.length == 0 }"
          v-waves
          @click="undisableDevice"
        >
          取消禁用
        </div>
      </div>
    </div>
    <div class="noDataBox" v-else>
      <PlaceholderMap :placeholderType="2"></PlaceholderMap>
    </div>
  </div>
</template>
<script>
import PlaceholderMap from "../../../../components/placeholderMap";
import {
  getUserDevicesList,
  disableUserDevices,
  cancelDisableUserDevices,
} from "@/api/newVersion/personalCenter";
export default {
  name: "DeviceInformation",
  components: {
    PlaceholderMap,
  },
  data() {
    return {
      // 请求的页码
      pageNum: 1,
      devicesOptions: [],
      checkAll: false,
      checkedDevices: [],
      isIndeterminate: false,
    };
  },
  props: {
    // 用户的详细信息
    personalInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentTabKey: {
      type: String,
      default: "personalInfo",
    },
  },
  watch: {
    // 监听是否需要加载群列表
    currentTabKey: {
      async handler(val, old) {
        if (val === "deviceInfo" && val !== old) {
          await this.getDeviceList();
        }
      },
      deep: true,
    },
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedDevices = val
        ? this.devicesOptions.map((item) => item.deviceNo)
        : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.devicesOptions.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.devicesOptions.length;
    },
    async disabledDevice() {
      if (this.checkedDevices.length == 0) {
        return;
      }
      let deviceNos = [];
      for (let i = 0; i < this.checkedDevices.length; i++) {
        const deviceNo = this.checkedDevices[i];
        const deviceInfo = this.devicesOptions.find(
          (el) => el.deviceNo == deviceNo
        );
        if (deviceInfo && !deviceInfo.freezeStatus) {
          deviceNos.push(deviceInfo.deviceNo);
        } else {
          continue;
        }
      }
      if (deviceNos.length == 0) {
        this.$message({
          type: "warning",
          message: "已禁用",
        });
        return;
      }
      const result = await disableUserDevices(deviceNos);
      if (result.code == 200) {
        this.$message({
          type: "success",
          message: "禁用成功",
        });
      }
      await this.getDeviceList();
    },
    async undisableDevice() {
      if (this.checkedDevices.length == 0) {
        return;
      }
      let deviceNos = [];
      for (let i = 0; i < this.checkedDevices.length; i++) {
        const deviceNo = this.checkedDevices[i];
        const deviceInfo = this.devicesOptions.find(
          (el) => el.deviceNo == deviceNo
        );
        if (deviceInfo && deviceInfo.freezeStatus) {
          deviceNos.push(deviceInfo.deviceNo);
        } else {
          continue;
        }
      }
      if (deviceNos.length == 0) {
        this.$message({
          type: "warning",
          message: "已取消禁用",
        });
        return;
      }
      const result = await cancelDisableUserDevices(deviceNos);
      if (result.code == 200) {
        this.$message({
          type: "success",
          message: "取消成功",
        });
      }
      await this.getDeviceList();
    },
    // 加载更多数据
    loadMoreData() {
      return new Promise(async (resolve, reject) => {
        this.pageNum++;
        await this.getDeviceList();
        resolve("success");
      });
    },
    // 获取数据的方法
    async getDeviceList() {
      const result = await getUserDevicesList(this.personalInfo.providerId);
      if (result.code == 200) {
        this.devicesOptions = result.data.data.map((item) => {
          item.deviceType = item.deviceType.toLowerCase();
          return item;
        });
        this.checkedDevices = [];
        this.checkAll = false;
        this.isIndeterminate = false;
        this.devicesOptions.forEach((v) => {
          if (v.freezeStatus) {
            this.checkedDevices.push(v.deviceNo);
          }
        });
        if (this.checkedDevices.length > 0) {
          this.checkAll =
            this.checkedDevices.length == this.devicesOptions.length;
          this.isIndeterminate =
            this.checkedDevices.length < this.devicesOptions.length;
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.wrapBox
  width 100%;
  min-height: 0;
  overflow: hidden;
  .deviceListBox
    width 100%;
    overflow hidden;
    background: rgba(255, 255, 255, 1);
    .checkBoxArea
      padding: 15px;
      ::v-deep .checkGroupArea
        width: 100% !important;
        .el-checkbox
          width: 150px !important;
          .el-checkbox__label
            padding-left: 5px;
    .operationArea
      width 100%;
      height 60px;
      border-top: 1px solid #f6f6f6;
      background: #ffffff;
      display flex;
      align-items: center;
      justify-content: center;
      .prohibitClicking
        background: #B3B3B3 !important;
        color: #ffffff !important;
        cursor: not-allowed !important;
      .commonStyle
        height: 36px;
        border-radius: 4px;
        cursor pointer;
        color: #ffffff;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      .disabledButton
        width: 88px;
        background: #E80E0E;
      .undisableButton
        width: 116px;
        background: #51BD68;
        margin-left: 15px;

  .noDataBox
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
</style>
