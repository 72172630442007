<template>
  <div class="pageHeaderBox">
    <div
      class="headerBackground"
      :class="personalInfo.vipStatus ? 'vipBanner' : ''"
    >
      <!-- {{personalInfo.providerId}}
      {{$store.getters.userInfo.id}} -->
      <div
        class="becomeVipBox"
        v-if="
          !personalInfo.vipStatus &&
            personalInfo.providerId === $store.getters.userInfo.id
        "
        @click="becomeVip"
      >
        <div class="vipIcon">
          <el-image
            style="width: 20px; height: 17px;"
            :src="require('../../../assets/images/pay/masonry.png')"
            fit="fill"
          >
          </el-image>
        </div>
        {{ $t("becomeApaidMember") }}
      </div>
      <div
        class="renewVipBox"
        v-if="
          personalInfo.vipStatus &&
            personalInfo.providerId === $store.getters.userInfo.id
        "
        @click="renewVip"
      >
        {{ $t("renewalPaidMember") }}
      </div>
    </div>
    <div class="showPersonalInfo">
      <div class="personalAvatar">
        <Avatar
          :path="
            personalInfo.isDelete != 2 && personalInfo.isDelete != 3
              ? personalInfo.profilePhoto
              : ''
          "
          :international="
            personalInfo.country !== '' ? Number(personalInfo.country) : 0
          "
          :needShowBorder="true"
          :isFrozen="personalInfo.isFrozen"
        ></Avatar>
      </div>
      <div class="firstRowBox">
        <div
          class="actionPostBox"
          v-if="personalInfo.isDelete != 2 && personalInfo.isDelete != 3"
        >
          <!-- 点赞 -->
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('thumbsUp')"
            placement="bottom"
          >
            <div
              class="actionStyle changelikeStatusStyle"
              @click.stop="changelikeStatus"
              :style="{ background: personalInfo.likeStatus ? '#FFF6F6' : '' }"
              v-loading="likeLoading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="#FFFFFF"
            >
              <span
                class="iconfont_Me iconStyle"
                :class="
                  personalInfo.likeStatus
                    ? 'icon-fabulous-fill'
                    : 'icon-fabulous'
                "
                :style="{ color: personalInfo.likeStatus ? '#FC4C4C' : '' }"
              ></span>
            </div>
          </el-tooltip>
          <SharePopover
            :shareConfig="shareConfig"
            @shareForMelinked="shareForMelinked"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="this.$t('share')"
              placement="bottom"
            >
              <div class="actionStyle shareButtonStyle">
                <span class="iconfont_Me icon-share iconStyle"></span>
              </div>
            </el-tooltip>
          </SharePopover>
          <!-- 投诉处理 -->
          <CommonPopover
            class="actionStyle otherStyle"
            v-if="operatingMenu.length !== 0 && !isSelf"
            :operatingMenu="operatingMenu"
            :width="width"
            :mode="mode"
            :shareConfig="shareConfig"
            @selectThisMenuItem="selectThisMenuItem"
          >
            <div class="commonPopoverButton">
              <i class="iconfont_Me icon-a-zu9781" style="font-size: 18px"></i>
            </div>
          </CommonPopover>
        </div>
      </div>
      <div class="secondRowBox">
        <div class="leftBox">
          <div
            class="personalName"
            v-if="personalInfo.isDelete != 2 && personalInfo.isDelete != 3"
          >
            <span
              class="nameSpan"
              :class="personalInfo.vipStatus ? 'vipName' : ''"
            >
              {{ personalInfo.name }}
            </span>
            <span
              v-if="personalInfo.gender && personalInfo.gender !== ''"
              class="iconfont_Me iconStyle"
              :class="
                personalInfo.gender == 'female'
                  ? 'icon-female-fill'
                  : 'icon-male-fill'
              "
              :style="{
                color: personalInfo.gender == 'female' ? '#F36172' : '#1CA9C7',
              }"
            ></span>
            <span
              v-if="
                personalInfo.thirdAccountType !== 0 &&
                  personalInfo.providerId === $store.getters.userInfo.id
              "
              class="iconfont_Me"
              :class="thirdAccountIcon"
              :style="{
                color: thirdAccountStyle,
                fontSize: '22px',
                marginLeft: '5px',
              }"
            ></span>
          </div>
          <div
            class="personalVip"
            v-if="personalInfo.vipStatus"
            @click.stop="handleClickVip(personalInfo.providerId)"
          >
            <div class="vipIcon">
              <el-image
                style="width: 20px; height: 17px;"
                :src="require('../../../assets/images/pay/masonry.png')"
                fit="fill"
              >
              </el-image>
            </div>
            <div class="vipTxt">
              {{ $t("paidMember") }} <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="personalIdOrTime">
            <img
              class="idIcon"
              :src="
                require('../../../assets/images/newPersonalCenter/idIcon.png')
              "
            />
            {{
              personalInfo.isDelete != 2 && personalInfo.isDelete != 3
                ? personalInfo.ucId
                : personalInfo.providerId
            }}
          </div>
          <div class="personalIdOrTime">
            <img
              class="idIcon"
              :src="
                require('../../../assets/images/newPersonalCenter/timeIcon.png')
              "
            />
            {{ personalInfo.joinTime }}
          </div>
        </div>
        <div
          class="rightBox"
          v-if="personalInfo.isDelete != 2 && personalInfo.isDelete != 3"
        >
          <div
            @click.stop="jumpEditPage"
            class="editButton buttonCommonStyle"
            v-if="personalInfo.providerId === $store.getters.userInfo.id"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('editpost')"
              placement="bottom"
            >
              <span class="iconfont_Me icon-edit1 iconStyle"></span>
            </el-tooltip>
          </div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              personalInfo.friendStatus ? $t('deleteFriends') : $t('addFrirend')
            "
            placement="bottom"
          >
            <div
              @click.stop="changeFriendStatus"
              class="editButton buttonCommonStyle"
              v-if="personalInfo.providerId !== $store.getters.userInfo.id"
              :style="{
                background:
                  blacklistButtonStatus && !personalInfo.friendStatus
                    ? '#B3B3B3'
                    : '',
                cursor:
                  blacklistButtonStatus && !personalInfo.friendStatus
                    ? 'not-allowed'
                    : '',
              }"
            >
              <span
                class="iconfont_Me iconStyle"
                :class="
                  personalInfo.friendStatus
                    ? 'icon-deleteuser-fill'
                    : 'icon-Adduser-fill'
                "
              ></span>
            </div>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('chat')"
            placement="bottom"
          >
            <div
              @click.stop="startChatting"
              class="commomButtonHover buttonCommonStyle"
            >
              <span
                class="iconfont_Me icon-information-fill iconStyle"
                :style="{ color: '#FFFFFF' }"
              ></span>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "../../components/avatarDisplay";
import SharePopover from "../../components/sharePopover";
import CommonPopover from "../../components/commonPopover";
import { changelikeStatus, deleteFriend } from "@/api/newVersion";
import { mapMutations } from "vuex";
import { Base64 } from "js-base64";
import { currentLoginVipStatus } from "@/api/newVersion/vip";
export default {
  name: "personalCenterHeader",
  components: {
    Avatar,
    SharePopover,
    CommonPopover,
  },
  props: {
    // 用户的详细信息
    personalInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 用户的黑名单信息
    blacklistInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // 改变喜欢的加载状态
      likeLoading: false,
      // 删除加载状态
      deleteLoading: false,
      width: 0,
      mode: "",
      //是否是vip
      isVip: true,
    };
  },
  computed: {
    // 分享所需要的参数
    shareConfig() {
      return {
        // 分享的内容类型(1:帖子,3:用户,4:群)
        share_type: 3,
        // 分享的内容Id
        share_id: this.personalInfo.providerId,
      };
    },
    isSelf() {
      return this.personalInfo.providerId === this.$store.getters.userInfo.id;
    },
    // 添加好友按钮显示的状态
    blacklistButtonStatus() {
      let blacklistButtonStatus = false;
      try {
        blacklistButtonStatus =
          this.blacklistInfo.positiveStatus ||
          this.blacklistInfo.reverseStatus ||
          this.$store.state.imStore.blackStatus;
      } catch (e) {
        console.log(e);
      }
      // this.$store.state.imStore.blackStatus

      return blacklistButtonStatus;
    },
    // 根据不同的黑名单关系展示不同的提示
    blacklistMessage() {
      let blacklistMessage = "";
      try {
        // 本地用户将此用户拉入黑名单
        if (
          this.blacklistInfo.positiveStatus ||
          this.$store.state.imStore.blackStatus
        ) {
          blacklistMessage = this.$t("blackListPositiveStatus");
          // 此用户将本地用户拉入黑名单
        } else if (this.blacklistInfo.reverseStatus) {
          blacklistMessage = this.$t("blackListReverseStatus");
        }
      } catch (error) {}
      return blacklistMessage;
    },
    // 第三方登录的图标
    thirdAccountIcon() {
      let thirdAccountIcon = "";
      switch (this.personalInfo.thirdAccountType) {
        case 1:
          thirdAccountIcon = "icon-qq";
          break;
        case 2:
          thirdAccountIcon = "icon-icon-test8";
          break;
        case 3:
          thirdAccountIcon = "icon-icon-test2";
          break;
        case 4:
          thirdAccountIcon = "icon-icon-test1";
          break;
        case 5:
          thirdAccountIcon = "icon-icon-test";
          break;
        case 6:
          thirdAccountIcon = "icon-icon-test6";
          break;
      }
      return thirdAccountIcon;
    },
    // 第三方登录图标颜色
    thirdAccountStyle() {
      let thirdAccountStyle = "";
      switch (this.personalInfo.thirdAccountType) {
        case 1:
          thirdAccountStyle = "#498BEE";
          break;
        case 2:
          thirdAccountStyle = "#46BB36";
          break;
        case 3:
          thirdAccountStyle = "#EF4B4B";
          break;
        case 4:
          thirdAccountStyle = "#0077B5";
          break;
        case 5:
          thirdAccountStyle = "#3375CB";
          break;
        case 6:
          thirdAccountStyle = "#00BDF3";
          break;
      }
      return thirdAccountStyle;
    },
    operatingMenu() {
      let operatingMenu = [];
      if (this.personalInfo.managerRole != 1) {
        operatingMenu.push({
          name: "shield",
          operatingFn: "shieldFn",
          tooltipContent: this.$t("block"),
        });
        this.width = 130;
        this.mode = "shield";
      }
      //是管理员并且不是自己则有删除权限
      if (this.$store.getters.userInfo.manager_role == 1 && !this.isSelf) {
        operatingMenu.push({
          iconClassName: "iconfont_Me icon-Iconly-Light-Delete-copy iconStyle",
          operatingFn: "deleteFn",
          tooltipContent: this.$t("delete"),
        });
      } else {
        //不是管理员则有投诉权限
        operatingMenu.push({
          iconClassName: "iconfont_Me icon-a-zu5510",
          operatingFn: "complaintFn",
          tooltipContent: this.$t("complaint"),
        });
      }
      return operatingMenu;
    },
  },
  methods: {
    // 跳到编辑页面
    jumpEditPage() {
      this.routeJump({
        name: "editCenter",
        params: {
          id: this.personalInfo.providerId,
          type: "personal",
        },
      });
    },
    // 改变好友状态
    changeFriendStatus() {
      // 只拦截可添加好友状态
      if (this.blacklistButtonStatus && !this.personalInfo.friendStatus) {
        this.$message({
          type: "error",
          message: this.blacklistMessage,
        });
        return;
      }
      if (this.personalInfo.friendStatus) {
        this.deleteFriend();
      } else {
        this.modifyFriendInformation();
      }
    },
    // 删除好友
    async deleteFriend() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      this.$confirm("", "", {
        confirmButtonText: " ",
        cancelButtonText: " ",
        type: "warning",
        center: true,
        iconClass: "el-icon-delete",
        customClass: "my-confirm",
        cancelButtonClass: "el-icon-close my-confirm-btn",
        confirmButtonClass: "el-icon-check my-confirm-btn",
      })
        .then(async () => {
          let params = {
            userId: this.$store.getters.userInfo.id,
            friendId: this.personalInfo.providerId,
            language: localStorage.getItem("langCode"),
          };
          this.friendStatusLoading = true;
          let result = await deleteFriend(params);
          this.friendStatusLoading = false;
          if (result.code) {
            this.personalInfo.friendStatus = !this.personalInfo.friendStatus;
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        })
        .catch((e) => {});
    },
    // 添加好友关系
    modifyFriendInformation() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      let userInfoForAddFriend = {
        id: this.personalInfo.providerId,
        avatar: this.personalInfo.profilePhoto,
        name: this.personalInfo.name,
        vipStatus: this.personalInfo.vipStatus,
      };
      this.$store.dispatch("commitOpenAddFriendPopup", userInfoForAddFriend);
    },
    // 分享帖子信息到IM
    shareForMelinked(shareData) {
      // 重置分享信息
      let sharingDataInfo = {
        // 分享的标题
        title: shareData.shareTitle,
        // 分享展示图片
        picurl: shareData.shareImageSrc,
        // 分享展示内容
        briefInfo: shareData.summary,
        // 分享的类型（个人：people；群组：group；工作：job；帖子：service；公司：company）
        serviceType: "people",
        // 分享对象的唯一ID
        uuid: this.personalInfo.providerId,
      };
      this.$store.dispatch("commitOpenSharePopup", sharingDataInfo);
    },
    // 改变喜欢状态
    async changelikeStatus() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      let params = {
        userId: this.$store.getters.userInfo.id,
        language: localStorage.getItem("langCode"),
        action: !this.personalInfo.likeStatus,
        type: 3,
      };
      this.likeLoading = true;
      let result = await changelikeStatus(this.personalInfo.providerId, params);
      this.likeLoading = false;
      if (result.code === 201) {
        this.$emit("refreshPersonalInfo");
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 删除操作
    handleDeletAeccount() {
      this.$store.commit("setDeleteDialogVisible", true);
      this.$store.commit("setDeleteType", "user");
      this.$store.commit("setDeleteProviderId", this.personalInfo.providerId);
      this.deleteLoading = true;
      setTimeout(() => {
        this.deleteLoading = false;
      }, 1000);
    },
    // 开始单人聊天
    async startChatting() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      this.chatWithAuthor(
        {
          thumb: this.personalInfo.profilePhoto,
          name: this.personalInfo.name,
          id: this.personalInfo.providerId,
          vipStatus: this.personalInfo.vipStatus,
        },
        "user"
      );
    },
    // 选择这个操作选项
    selectThisMenuItem(_, item) {
      switch (item.operatingFn) {
        //投诉处理
        case "complaintFn":
          //投诉处理
          this.$store.commit("setComplaintType", 3);
          this.$store.commit("setComplaintId", this.personalInfo.providerId);
          this.$store.commit("setPersonalInfo", this.personalInfo);
          this.$store.commit("setComplaintDialogVisible", true);
          break;
        //删除处理
        case "deleteFn":
          this.handleDeletAeccount();
          break;
      }
    },
    //成为付费会员
    becomeVip() {
      this.$store.commit("setVipModeStatus", true);
    },
    renewVip() {
      this.$store.commit("setIsVipModeStatus", true);
    },
    async showIsVipMode() {
      // 先判断自己是否是会员，如果自己是会员跳转到自己会员页，如果不是会员则弹出开通会员
      const loginVipStatus = await currentLoginVipStatus();
      try {
        if (loginVipStatus.data.data.vipStatus) {
          this.$store.commit("setIsVipModeStatus", true);
        } else {
          this.$store.commit("setVipModeStatus", true);
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: error,
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.pageHeaderBox {
  width: 100%;
  overflow: hidden;

  .headerBackground {
    width: 100%;
    height: 140px;
    background: url('../../../assets/images/newPersonalCenter/backgroundPicture.png') no-repeat;
    background-size: 100% 100%;
    position:relative;
    &.vipBanner{
       background: url('../../../assets/images/pay/vipbanner.png') no-repeat;
    }
    .becomeVipBox{
      padding:8px 10px;
      background: linear-gradient(90deg,#ffe4b1 1%, #ffc363);
      border-radius: 20px;
      box-shadow: 0px 6px 6px 0px rgba(0,0,0,0.16);
      right: 18px;
      bottom:18px;
      position:absolute;
      color: #232323;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor pointer
      .vipIcon{
        margin-right:8px
        display: flex;
        align-items: center;
      }
    }

    .renewVipBox{
      border-radius: 20px;
      right: 18px;
      bottom:18px;
      position:absolute;
      color: #232323;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor pointer
      // width: 170px;
      padding:0 20px;
      height: 40px;
      line-height 40px;
      background: #764d13;
      border-radius: 50px;
      box-shadow: 0px 4px 10px 0px #cda15c;
      font-size: 16px;
      color: #fff6e8;
    }
  }

  .showPersonalInfo {
    width: 100%;
    height: 226px;
    background: #FFFFFF;
    position: relative;

    .personalAvatar {
      width: 122px;
      height: 122px;
      position: absolute;
      top: -40px;
      left: 40px;
    }

    .firstRowBox {
      width: 100%;
      height: 40px;
      padding-top: 30px;
      box-sizing: content-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .actionPostBox {
        height: 40px;
        margin-right: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .actionStyle {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background: #F7F7F7;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.3s, background 0.3s;

          .iconStyle {
            color: #666666;
            font-size: 22px;
            transition: color 0.3s;
          }

          &:active {
            transform: scale(1.1);
          }
        }

        .changelikeStatusStyle {
          margin-right: 40px;

          &:hover {
            background: #FFF6F6;

            .iconStyle {
              color: #FC4C4C;
            }
          }
        }

        .shareButtonStyle {
          &:hover {
            background: #F2FCF8;

            .iconStyle {
              color: #33CC66;
            }
          }
        }

        .otherStyle {
          margin-left: 40px;

          .el-icon-more {
            color: #666;
            transform: rotate(90deg);
          }
        }
      }
    }

    .secondRowBox {
      left: 0;
      bottom: 20px;
      width: 100%;
      position: absolute;
      padding: 0 25px 0 40px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .leftBox {
        flex: 1;
        min-height: 0;
        min-width: 0;
        overflow: hidden;

        .personalName {
          width: 100%;
          height: 24px;
          font-size: 18px;
          font-weight: bold;
          color: #373737;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .nameSpan {
            display: inline-block;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .vipIcon{
            // margin-left:4px;
            display:flex;
            align-items: center;
            transform: scale(0.8)
          }
          .iconStyle {
            font-size: 18px;
          }
        }

        .personalIdOrTime {
          width: 100%;
          height: 24px;
          font-size: 14px;
          color: #8F8F8F;
          margin-top: 10px;
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .idIcon {
            width: 14px;
            height: 14px;
            overflow: hidden;
            margin-right: 10px;
          }
        }


        .personalVip{
          display: flex;
          margin-top:10px;
          font-size: 11px;
          color: #f8873a;
          display: flex
          width: fit-content;
          position: relative;
          cursor: pointer;
          .vipIcon{
            // position: absolute;
            // left:0;
          }
          .vipTxt{
            height: 17px;
            line-height: 17px;
            box-sizing: border-box;
            background: #fff1d9;
            border-radius: 0px 0px 5px 0px;
            padding: 0px 4px 0px 12px;
            margin-left: -8px;
            white-space: nowrap
           }
        }
      }
      .rightBox {
        width: 220px;
        min-height: 40px;
        flex-shrink: 0;
        display: flex;
        flex-flow: row-reverse;
        align-items: center;
        justify-content: space-between;

        .buttonCommonStyle {
          width: 107px;
          height: 40px;
          border-radius: 6px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;

          .iconStyle {
            font-size: 24px;
          }
        }

        .editButton {
          cursor: pointer;
          background: #E6E6E6;
          transition: background 0.3s;

          &:hover {
            background: #F0F0F0;
          }

          .iconStyle {
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
<style lang="stylus">
.deleteFriendConfirm {
  display: flex;
  align-items: center;
  justify-content: center;

  .iconStyle {
    font-size: 38px;
  }

  b {
    top: auto;
  }
}

.pageHeaderBox {
  .firstRowBox {
    .actionPostBox {
      .el-loading-spinner {
        margin-top: -10px;

        .circular {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>
