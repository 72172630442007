<template>
  <div class="wrapBox" v-loading="dataLoading">
    <PostTypeSelection
      :style="{
        height: '50px',
        opacity: '1',
        overflow: 'visible',
      }"
      :setDefault="typeSelection"
      @confirm="selectedType"
      v-if="showSelection"
    ></PostTypeSelection>
    <template v-if="postInfoList.length !== 0">
      <div
        class="postInformationItem"
        v-for="(postInfo, index) in postInfoList"
        :key="postInfo.gigId + index"
      >
        <PostInformationItem
          :postInfo="postInfo"
          :operatingMenu="operatingMenu(postInfo)"
          :fromType="'self'"
        ></PostInformationItem>
      </div>
    </template>
    <div class="noDataBox" v-if="postInfoList.length === 0 && !dataLoading">
      <PlaceholderMap :placeholderType="2"></PlaceholderMap>
    </div>
  </div>
</template>
<script>
import PlaceholderMap from "../../../../components/placeholderMap";
import {
  getPostListData,
  deletePost,
  shieldPost,
  getUserCategories,
} from "@/api/newVersion/personalCenter";
import PostInformationItem from "../../../../components/homeDisplayComponents/postInformationItem";
import {
  getFavoritesCount,
  startCollection,
  startUnfavorite,
} from "@/api/newVersion/interfaceForFavorites";
import PostTypeSelection from "@/newVersion/components/postTypeSelection";
export default {
  name: "postInformation",
  components: {
    PostInformationItem,
    PlaceholderMap,
    PostTypeSelection,
  },
  props: {
    // 用户的详细信息
    personalInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentTabKey: {
      type: String,
      default: "personalInfo",
    },
  },
  watch: {
    // 监听是否需要加载群列表
    currentTabKey: {
      async handler(val, old) {
        if (val === "postInfo" && val !== old) {
          this.dataLoading = true;
          await this.getPostData();
          this.dataLoading = false;
        }
      },
      deep: true,
    },
    // 监听列表数据中的收藏状态变化(主要是为了监听在收藏弹窗中操作了数据过后改变帖子列表收藏状态)
    "$store.state.favorites.hasBeenOperatedObject": {
      handler(val, old) {
        if (val && val !== old && this.$route.name === "personalCenter") {
          this.modifPostFavoriteStatus(val);
          this.$store.commit("setHasBeenOperatedObject", null);
          this.$forceUpdate();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      // 数据加载loading
      dataLoading: false,
      // 帖子列表数据集合
      postInfoList: [],
      // 请求的页码
      pageNum: 1,
      // 被选中删除的群组
      waitForDeletePost: null,
      // 已有分类集合
      typeSelection: [],
      // 展示分类选择条
      showSelection: false,
    };
  },
  mounted() {
    this.getUserCategories();
  },
  methods: {
    // 获取收藏夹总数
    getFavoritesCountData() {
      return new Promise(async (resolve, reject) => {
        let result = await getFavoritesCount();
        if (result.code == 200) {
          resolve(result.data.data);
        } else {
          reject(result.message);
        }
      });
    },
    // 获取用户已有分类
    async getUserCategories() {
      const result = await getUserCategories();
      if (result.code == 200) {
        this.typeSelection = result.data.data;
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
      await this.$nextTick();
      this.showSelection = true;
    },
    // 操作菜单
    operatingMenu(postInfo) {
      let operatingMenu = [];
      // 只有当用户自己浏览自己的帖子记录时，才可以操作帖子
      if (
        this.$store.getters.userInfo.id &&
        this.personalInfo.providerId === this.$store.getters.userInfo.id
      ) {
        operatingMenu = [
          {
            iconClassName: "icon-enclosure1",
            operatingFn: this.editPost,
            tooltipContent: this.$t("editpost"),
          },
          {
            iconClassName: "icon-delet",
            operatingFn: this.deleteConfirm,
            tooltipContent: this.$t("delete"),
          },
        ];
      } else {
        operatingMenu = [
          {
            imageSrc: postInfo.bookMarkStatus
              ? require("../../../../../assets/images/favorites/favorited.png")
              : require("../../../../../assets/images/favorites/notFavorited.png"),
            imageWidth: 14,
            imageHeight: 13,
            operatingFn: this.collectionAction,
            tooltipContent: this.$t("collection"),
          },
        ];
        // 是管理员或者是自己的帖子,显示删除图标
        if (
          this.$store.getters.userInfo.manager_role == 1 ||
          this.isSelfPublish(postInfo)
        ) {
          operatingMenu.push({
            iconClassName: "icon-delet",
            operatingFn: this.deleteConfirm,
            tooltipContent: this.$t("delete"),
          });
        }
        // 不是管理员，也不是自己的帖子，显示投诉图标 && 屏蔽图标
        if (
          this.$store.getters.userInfo.manager_role != 1 &&
          !this.isSelfPublish(postInfo)
        ) {
          operatingMenu.push(
            {
              iconClassName: "iconfont_Me icon-a-zu5510",
              operatingFn: this.complaintConfirm,
              tooltipContent: this.$t("complaint"),
            },
            {
              iconClassName: "iconfont_Me icon-a-zu9817",
              operatingFn: this.shieldConfirm,
              tooltipContent: this.$t("shield"),
            }
          );
        }
      }
      return operatingMenu;
    },
    complaintConfirm(postInfo) {
      this.$store.commit("setComplaintType", 1);
      this.$store.commit("setComplaintId", postInfo.gigId);
      this.$store.commit("setComplaintDialogVisible", true);
    },
    shieldConfirm(postInfo) {
      this.confirm(null, this.$t("blockThisPost"), null, () => {
        this.shieldPost(postInfo);
      });
    },
    //屏蔽帖子
    async shieldPost(postInfo) {
      let result = await shieldPost(postInfo.gigId);
      if (result.code == 200) {
        this.$nextTick(async () => {
          await this.getPostData();
          this.dataLoading = false;
        });
        this.$message({
          message: "success",
          type: "success",
        });
      }
    },
    // 收藏操作
    async collectionAction(postInfo) {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      // 如果已经是收藏状态的话，则执行取消收藏操作
      if (postInfo.bookMarkStatus) {
        await this.submitUnfavoriteInfo(postInfo);
      } else {
        // 这里需要获取一下是否有收藏夹，如果没有的话就直接收藏，如果有的话则先打开收藏弹窗
        this.getFavoritesCountData()
          .then(async (result) => {
            let handlerPostInfo = _.cloneDeep(postInfo);
            handlerPostInfo.favoritesType = "post";
            if (result > 0) {
              // 将当前帖子基本信息存入收藏状态管理器
              this.$store.commit(
                "setNeedToOperatObjectInformation",
                handlerPostInfo
              );
              // 这一步是展示选择收藏夹组件
              this.$store.commit("setNeedToShowInDialog", "selectFavoritesBox");
              // 打开收藏操作弹窗
              this.$store.commit("setFavoritesDialogStatus", true);
            } else {
              // 如果没有收藏夹，则直接收藏
              await this.favoriteCurrentPost(handlerPostInfo);
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error,
            });
          });
      }
    },
    // 取消收藏
    async submitUnfavoriteInfo(postInfo) {
      let result = await startUnfavorite(postInfo.gigId);
      if (result.code == 200) {
        // 这里存储用于刷新列表帖子收藏状态
        this.$store.commit("setHasBeenOperatedObject", postInfo);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 收藏当前帖子
    favoriteCurrentPost(postInfo) {
      return new Promise(async (resolve, reject) => {
        let dataId = postInfo.gigId;
        let params = { type: "1" };
        let result = await startCollection(dataId, params);
        if (result.code == 200) {
          // 这里存储用于刷新列表帖子收藏状态
          this.$store.commit("setHasBeenOperatedObject", postInfo);
          // ===================================================================================================
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      });
    },
    // 修改帖子的收藏状态
    modifPostFavoriteStatus(alreadyEditedItem) {
      this.postInfoList = this.postInfoList.map((item) => {
        if (item.gigId === alreadyEditedItem.gigId) {
          item.bookMarkStatus = !item.bookMarkStatus;
        }
        return item;
      });
      this.$forceUpdate();
    },

    // 确认删除弹窗
    deleteConfirm(item) {
      // 自己删除自己的帖子
      if (this.isSelfPublish(item)) {
        this.waitForDeletePost = item;
        this.confirm(null, null, "el-icon-delete", this.deletePost);
      } else {
        this.$store.commit("setDeleteType", "post");
        this.$store.commit("setDeletePostId", item.gigId);
        this.$store.commit("setDeleteDialogVisible", true);
      }
    },
    // 判断是否是自己发布的帖子
    isSelfPublish(item) {
      return this.$store.getters.userInfo.id == item.providerId;
    },
    // 删除帖子
    async deletePost() {
      let params = {
        helogigId: this.waitForDeletePost.gigId,
        userId: this.$store.getters.userInfo.id,
      };
      let result = await deletePost(params);
      if (result) {
        this.pageNum = 1;
        this.postInfoList = [];
        this.dataLoading = true;
        this.$nextTick(async () => {
          await this.getPostData();
          this.dataLoading = false;
        });
      }
    },
    // 编辑帖子信息
    async editPost(item) {
      this.routeJump({ name: "newPublish", params: { id: item.gigId } });
    },
    // 加载更多数据
    loadMoreData() {
      return new Promise(async (resolve, reject) => {
        this.pageNum++;
        this.dataLoading = true;
        await this.getPostData();
        this.dataLoading = false;
        resolve("success");
      });
    },
    // 获取数据的方法
    getPostData(categoryIds = "") {
      return new Promise(async (resolve, reject) => {
        let params = {
          page: this.pageNum,
          pageSize: 20,
          categories: categoryIds,
        };
        if (this.personalInfo.providerId) {
          let result = await getPostListData(
            this.personalInfo.providerId,
            params
          );
          if (result.code == 200) {
            if (result.data.data.length !== 0) {
              // 当时请求的第一页的时候，清空列表数据
              if (this.pageNum === 1) {
                this.postInfoList = [];
              }
              this.postInfoList = this.postInfoList.concat(result.data.data);
              // 过滤一次,防止有相同的数据出现
              this.postInfoList = this.filterAnArrayOfObjects(
                this.postInfoList,
                "gigId"
              );
            } else if (this.pageNum === 1) {
              this.postInfoList = [];
            } else if (this.pageNum > 1) {
              this.pageNum--;
            }
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
          resolve("success");
        }
      });
    },
    // 选中类型
    async selectedType(categoryIds) {
      this.dataLoading = true;
      await this.getPostData(categoryIds);
      this.dataLoading = false;
    },
  },
};
</script>
<style lang="stylus" scoped>
.wrapBox {
  width: 100%;
  min-height: 0;
  overflow: hidden;

  .noDataBox {
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .postInformationItem {
    width: 100%;
    min-height: 0px;
    overflow: hidden;
    background: #FFFFFF;
    margin-bottom: 10px;
  }
}
</style>
