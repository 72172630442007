<template>
  <div
    class="centerPage"
    v-infinite-scroll="startLoadData"
    infinite-scroll-disabled="loadDataDisabled"
    infinite-scroll-distance="10"
    infinite-scroll-immediate-check="false"
  >
    <div class="centerPageContent">
      <PersonalCenterHeader
        :personalInfo="personalInfo"
        :blacklistInfo="blacklistInfo"
        @refreshPersonalInfo="getPersonalInfoById"
      ></PersonalCenterHeader>
      <div class="contentBottom">
        <div class="leftSidebar">
          <div class="tabbarOuter">
            <CustomTabbar
              @selectThisTabIndex="selectThisTabIndex"
              :tabbarParam="tabbarParam"
            ></CustomTabbar>
          </div>
          <div class="infoContent">
            <PersonalInfoContent
              ref="personalInfoContent"
              :personalInfo="personalInfo"
              :currentTabKey="currentTabKey"
            >
            </PersonalInfoContent>
          </div>
        </div>
        <div class="rightSidebar">
          <div
            class="thirdPartyLoginTips"
            v-if="
              !personalInfo.hasPwd &&
                personalInfo.providerId === $store.getters.userInfo.id
            "
          >
            <div class="tipsText">
              {{ $t("thirdPartyLoginTips") }}
            </div>
            <div
              class="goToSetPassword"
              @click.stop="thirdAccountToSetPassword"
            >
              <img
                :style="{ width: '15px', height: '17px' }"
                :src="
                  require('../../../assets/images/newPersonalCenter/goToSetPasswordIcon.png')
                "
                alt=""
              />
            </div>
          </div>
          <MelinkedTips></MelinkedTips>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PersonalInfoContent from "./personalInfoContent";
import PersonalCenterHeader from "./personalCenterHeader";
import CustomTabbar from "../../components/customTabbar";
import MelinkedTips from "../../components/melinkedTips";
import Bus from "@/utils/bus.js";
import * as RESTApi from "@/api/rest";
import {
  getPersonnelDetails,
  getThirdAccountHasPwd,
} from "@/api/newVersion/personalCenter";
export default {
  name: "personalCenter",
  components: {
    MelinkedTips,
    CustomTabbar,
    PersonalCenterHeader,
    PersonalInfoContent,
  },
  data() {
    return {
      // 用户ID
      personalId: "",
      // 用户的详细信息
      personalInfo: {},
      // 黑名单信息
      blacklistInfo: {},
      // 当前应该展示tab页面下标
      tabIndex: 0,
      // 正在加载中,禁止再触发
      loadDataDisabled: false,
      isScrollUp: true,
    };
  },
  mounted() {
    Bus.$on("upDateVipInfo", (msg) => {
      this.getPersonalInfoById();
    });
  },
  beforeDestroy() {
    Bus.$off("upDateVipInfo");
  },
  computed: {
    // tabbar数据
    tabbarParam() {
      let tabbarParam = [
        {
          className: null, //没有class就是图片，
          selectedImageSrc: require("../../../assets/images/newPersonalCenter/personalInfoIconSelected.png"),
          unSelectedImageSrc: require("../../../assets/images/newPersonalCenter/personalInfoIconUnSelected.png"),
          width: 20, //用图片的话必须配置宽高
          height: 16, //用图片的话必须配置宽高
          isSelected: false,
          tooltipContent: this.$t("personnalInfomation"),
          key: "personalInfo",
        },
        {
          className: "icon-group-fill", //没有class就是图片，
          selectedImageSrc: null,
          unSelectedImageSrc: null,
          isSelected: false,
          tooltipContent: this.$t("groups"),
          key: "groupInfo",
        },
        {
          className: "icon-Post-fill", //没有class就是图片，
          selectedImageSrc: null,
          unSelectedImageSrc: null,
          isSelected: false,
          tooltipContent: this.$t("posts"),
          key: "postInfo",
        },
      ];
      // 如果是自己查看自己的话，就显示收藏模块
      if (this.personalInfo.providerId === this.$store.getters.userInfo.id) {
        tabbarParam.push({
          className: null, //没有class就是图片，
          selectedImageSrc: require("../../../assets/images/newPersonalCenter/favoritesIconSelected.png"),
          unSelectedImageSrc: require("../../../assets/images/newPersonalCenter/favoritesIconUnSelected.png"),
          width: 20, //用图片的话必须配置宽高
          height: 20, //用图片的话必须配置宽高
          isSelected: false,
          tooltipContent: this.$t("collection"),
          key: "collection",
        });
      }
      // 判断是不是管理员
      if (this.$store.getters.userInfo.manager_role == "1") {
        tabbarParam.push({
          className: null, //没有class就是图片，
          selectedImageSrc: require("../../../assets/images/newPersonalCenter/deviceInformation.png"),
          unSelectedImageSrc: require("../../../assets/images/newPersonalCenter/deviceInformation.png"),
          width: 27, //用图片的话必须配置宽高
          height: 23, //用图片的话必须配置宽高
          isSelected: false,
          tooltipContent: "设备信息",
          key: "deviceInfo",
        });
      }
      let tabIndex = this.tabIndex !== -1 ? this.tabIndex : 0;
      tabbarParam[tabIndex].isSelected = true;
      return tabbarParam;
    },
    currentTabKey() {
      if (!this.tabbarParam[this.tabIndex]) {
        return "null";
      }
      return this.tabbarParam[this.tabIndex].key;
    },
  },
  watch: {
    // 监听个人中心用户ID变化
    "$route.params.id": {
      handler(val, old) {
        if (val !== old && this.$route.name === "personalCenter") {
          this.personalId = this.$Base64.decode(val.replace("pai_", ""));
          try {
            if (document.querySelector(".centerPage").scrollTop != 0) {
              document.querySelector(".centerPage").scrollTop = 0;
            }
          } catch (error) {}
          this.selectThisTabIndex(0);
          this.$nextTick(async () => {
            await this.getPersonalInfoById();
          });
        }
      },
      deep: true,
    },
  },
  async created() {
    // 如果没有带ID过来，就强制跳转到主页
    if (this.$route.params.id) {
      this.personalId = this.$Base64.decode(
        this.$route.params.id.replace("pai_", "")
      );
      await this.getPersonalInfoById();
      await this.getBlacklistStatus();
      this.$nextTick(() => {
        // 根据指向跳转到对应tab
        switch (this.$route.params.active) {
          case "group":
            this.selectThisTabIndex(1);
            break;
          case "post":
            this.selectThisTabIndex(2);
            break;
          default:
            // this.selectThisTabIndex(0);
            break;
        }
      });
    } else {
      this.routeJump({ name: "home" });
    }
  },
  // 监听是否是从子项详情页面过来的，如果是的话，就获取之前存储的下标，跳转到对应的下标上去
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "groupCenter" || from.name === "postContentDetails") {
        vm.$nextTick(() => {
          vm.selectThisTabIndex(
            vm.$store.state.personalCenter.personalInfoIndex
          );
          setTimeout(() => {
            vm.$store.state.personalCenter.scrollTopInstance &&
              (document.querySelector(".centerPage").scrollTop =
                vm.$store.state.personalCenter.scrollTopInstance);
          }, 1000);
        });
      } else {
        // 如果是从其他页面进来的，就将记录重置
        vm.$store.commit("setScrollTopInstance", 0);
        vm.$store.commit("setPersonalInfoIndex", 0);
        vm.$forceUpdate();
      }
    });
  },
  // 监听是否离开页面进入对应子项的详情页面，是的话就将tab下标存储起来
  beforeRouteLeave(to, from, next) {
    if (to.name === "groupCenter" || to.name === "postContentDetails") {
      this.$store.commit("setPersonalInfoIndex", this.tabIndex);
      // 储存当前的滚动位置
      this.$store.commit(
        "setScrollTopInstance",
        document.querySelector(".centerPage").scrollTop
      );
    } else {
      // 如果是离开搜索页面，则将下标记录设置为空
      this.$store.commit("setPersonalInfoIndex", 0);
    }
    next();
  },
  methods: {
    // 开始触发加载新数据(这里主要是用于将事件传递给子组件，让子组件触发)
    async startLoadData() {
      this.loadDataDisabled = true;
      await this.$refs.personalInfoContent.startLoadData();
      this.loadDataDisabled = false;
    },
    // 获取与用户之前的黑名单关系
    async getBlacklistStatus() {
      let result = await RESTApi.getBlacklistStatus([this.personalId]);
      if (result.code == 200) {
        try {
          this.blacklistInfo = result.data.data[0];
        } catch (error) {
          console.log(error);
        }
      }
    },

    // 根据ID获取用户的详细信息
    async getPersonalInfoById() {
      // 请求不同的接口来组装用户信息
      let result = await getPersonnelDetails(this.personalId);
      let infoData = await RESTApi.getProfileInfoData({
        providerId: this.personalId,
      });
      let managerRole = infoData.data[0].managerRole;
      let vipStatus = infoData.data[0].vipStatus;
      if (result.code == 200) {
        this.personalInfo = result.data.data;
        if (
          infoData.data[0].provider_state !== "" &&
          infoData.data[0].provider_state.startsWith("2")
        ) {
          this.personalInfo.isFrozen = true;
        } else {
          this.personalInfo.isFrozen = false;
        }
        this.personalInfo.name = infoData.data[0].name;
        this.personalInfo.profilePhoto = infoData.data[0].profile_photo;
        // 先把第三方设置密码提示默认隐藏
        this.personalInfo.hasPwd = true;
        this.personalInfo.managerRole = managerRole;
        this.personalInfo.vipStatus = vipStatus;
        // 这里记录一下是为了触发子页面的列表数据刷新，先设置为-1再还原回去，触发刷新
        let oldTabIndex = this.tabIndex;
        this.tabIndex = -1;
        this.$forceUpdate();
        this.$nextTick(() => {
          this.tabIndex = oldTabIndex;
          // 只有当自己进入自己的个人中心的时候，才会检查是否设置过登录密码
          if (
            this.personalInfo.providerId === this.$store.getters.userInfo.id
          ) {
            this.thirdAccountHasPwd();
          }
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 如果是第三方用户登录的话，判断这个用户是否设置过密码
    async thirdAccountHasPwd() {
      let result = await getThirdAccountHasPwd();
      if (result.code == 200) {
        this.personalInfo.hasPwd = result.data.data;
        this.$forceUpdate();
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 获取选择对应tab的下标
    selectThisTabIndex(index) {
      this.tabIndex = index;
    },
    // 三方登录设置密码
    thirdAccountToSetPassword() {
      this.$store.commit("setiIsThirdAccountSetNewPassword", true);
      this.$nextTick(() => {
        this.$store.commit("setLoginAndRegisterDialog", true);
      });
    },
    // 返回主页
    goBackToHomePage() {
      this.routeJump({ name: "home" });
    },
  },
};
</script>
<style lang="stylus" scoped>
.centerPage {
  width: 100%;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  background: #F7F7F7;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
  }

  .centerPageContent {
    width: 1160px;
    min-height: 100%;
    margin: auto;

    .contentBottom {
      width: 100%;
      margin-top: 10px;
      min-height: 60px;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .leftSidebar {
        flex: 1;
        min-width: 0;
        min-height: 60px;
        overflow: hidden;

        .tabbarOuter {
          width: 100%;
          height: 60px;
          background: #FFFFFF;
        }
      }

      .rightSidebar {
        width: 278px;
        overflow: hidden;
        min-height: 114px;
        flex-shrink: 0;
        margin-left: 16px;

        .thirdPartyLoginTips {
          width: 100%;
          height: 128px;
          padding: 0 30px;
          box-sizing: border-box;
          background: #DCF8EF;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: space-around;
          margin-bottom: 10px;

          .tipsText {
            font-size: 14px;
            color: #343434;
          }
        }

        .goToSetPassword {
          width: 100px;
          height: 30px;
          box-sizing: border-box;
          border: 1px solid #33CC66;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 15px;
          cursor: pointer;
          transition: background 0.3s;

          &:hover {
            background: #F2FCF8;
          }
        }
      }
    }
  }
}

.detailsPage {
  // width: 100vw;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  background: #F7F7F7;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  .detailsPageContent {
    width: 1160px;
    height: 100%;
    overflow: hidden;
    display: flex;
    margin-top: 15px;
    align-items: flex-start;
    justify-content: space-between;

    .detailsContent {
      width: 866px;
      max-height: 100%;
      background: #ffffff;

      .contentHeader {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #F0F0F0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: height 0.3s, opacity 0.3s;

        .iconStyle1 {
          font-size: 22px;
          color: #333333;
          margin-right: 8px;
          cursor: pointer;
        }

        .iconStyle2 {
          font-size: 22px;
          color: #8F8F8F;
          cursor: pointer;
        }
      }

      .contentBox {
        width: 100%;
        padding: 24px 30px;
        overflow-x: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        transition: height 0.3s;
        scrollbar-width: none;
        position: relative;

        &::-webkit-scrollbar {
          width: 0px;
        }

        .opration {
          position: absolute;
          right: 30px;
          top: 24px;
        }

        .commonPopoverStyle {
          position: absolute;
          top: 20px;
          right: 30px;
          z-index: 2;

          .commonPopoverButton {
            width: 24px;
            height: 20px;
            cursor: pointer;
            border-radius: 4px;
            background: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .containerForStyle {
          width: 100%;
          box-sizing: border-box;
          padding-left: 66px;

          .labelRow {
            width: 100%;
            height: auto;
            line-height: 40px;
            overflow: hidden;
          }
        }

        .actionPostBox {
          height: 60px;
          width: 100%;
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          .actionStyle {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            background: #F7F7F7;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s, background 0.3s;

            .iconStyle {
              color: #666666;
              font-size: 32px;
              transition: color 0.3s;
            }

            &:active {
              transform: scale(1.1);
            }
          }

          .changelikeStatusStyle {
            margin-right: 25px;

            &:hover {
              background: #FFF6F6;

              .iconStyle {
                color: #FC4C4C;
              }
            }
          }

          .shareButtonStyle {
            &:hover {
              background: #F2FCF8;

              .iconStyle {
                color: #33CC66;
              }
            }
          }
        }

        .intervalLine {
          width: 100%;
          height: 1px;
          margin-top: 30px;
          background: #F0F0F0;
        }
      }
    }
  }
}

.no-data {
  border-top: none;

  .detailsPageContent {
    align-items: center !important;

    .detailsContent {
      .contentHeader {
        border-bottom: 10px solid #f7f7f7 !important;
      }
    }
  }

  .no-data-wrap {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 542px;
    flex-direction: column;
    font-size: 14px;
    color: #ccc;

    .no-data-img {
      width: 95px;
      height: 62px;
      margin-bottom: 10px;
    }
  }
}
</style>
