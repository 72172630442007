var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.dataLoading,
          expression: "dataLoading"
        }
      ],
      staticClass: "wrapBox"
    },
    [
      _c(
        "div",
        { staticClass: "favoritesHeader" },
        [
          _c("span", { staticClass: "favoritesTitle" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.favoritesName) +
                "  (" +
                _vm._s(_vm.favoritesShowCount) +
                ")\n    "
            )
          ]),
          _c(
            "FavoritesPopover",
            {
              attrs: { favoritesList: _vm.favoritesList },
              on: { selectThisFavorites: _vm.selectThisFavorites }
            },
            [
              _c("div", { staticClass: "favoritesIcon" }, [
                _c("img", {
                  staticClass: "iconImage",
                  attrs: {
                    src: require("../../../../../assets/images/newPersonalCenter/favoritesIcon.png")
                  }
                })
              ])
            ]
          )
        ],
        1
      ),
      _vm._l(_vm.collectList, function(favoritesItem, index) {
        return _c(
          "div",
          { key: favoritesItem.collectId, staticClass: "favoritesItem" },
          [
            favoritesItem.type === "1"
              ? _c("PostInformationItem", {
                  attrs: {
                    postInfo: favoritesItem,
                    operatingMenu: _vm.operatingMenu,
                    fromType: "favorites"
                  }
                })
              : _vm._e()
          ],
          1
        )
      }),
      _vm.collectList.length === 0
        ? _c(
            "div",
            { staticClass: "noDataBox" },
            [_c("PlaceholderMap", { attrs: { placeholderType: 2 } })],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }