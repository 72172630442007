<template>
  <el-popover
    width="286"
    placement="bottom"
    trigger="hover"
    :offset="-105"
    :visible-arrow="false"
    popper-class="favoritesPopover"
  >
    <FavoritesHeader @addNewFavorites="addNewFavorites"></FavoritesHeader>
    <div class="headerIntervalLine"></div>
    <div class="noDataContent" v-if="favoritesList.length === 0">
      <PlaceholderMap :placeholderType="2"></PlaceholderMap>
    </div>
    <div class="favoritesListContent" v-if="favoritesList.length !== 0">
      <div class="favoritesItem" @click.stop="selectThisFavorites(-1)">
        <div class="favoritesLeft">
          <div class="favoritesName">
            {{ $t("allCollections") }}
          </div>
          <div class="favoritesCount">
            {{ `(${favoritesCount})` }}
          </div>
        </div>
      </div>
      <div
        class="favoritesItem"
        v-for="(item, index) in favoritesList"
        :key="index"
        :title="item.name"
        @click.stop="selectThisFavorites(index)"
      >
        <div class="favoritesLeft">
          <div class="favoritesName">
            {{ item.name }}
          </div>
          <div class="favoritesCount">
            {{ `(${item.count})` }}
          </div>
        </div>
        <CommonPopover
          :operatingItem="item"
          :operatingMenu="operatingMenu"
          @selectThisMenuItem="selectThisMenuItem"
        >
          <div class="favoritesRight">
            <img
              :src="
                require('../../../assets/images/newPersonalCenter/favoritesOperating.png')
              "
            />
          </div>
        </CommonPopover>
      </div>
    </div>
    <slot slot="reference"></slot>
  </el-popover>
</template>
<script>
import PlaceholderMap from "../placeholderMap";
import FavoritesHeader from "./favoritesHeader";
import CommonPopover from "../commonPopover";
import { deleteFavorites } from "@/api/newVersion/interfaceForFavorites";
export default {
  name: "favoritesPopover",
  components: {
    PlaceholderMap,
    FavoritesHeader,
    CommonPopover,
  },
  props: {
    // 收藏夹列表
    favoritesList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      // 操作菜单
      operatingMenu: [
        {
          iconClassName: "icon-write",
          tooltipContent: this.$t("editpost"),
        },
        {
          iconClassName: "icon-delet",
          tooltipContent: this.$t("delete"),
        },
      ],
    };
  },
  computed: {
    // 收藏数据总计
    favoritesCount() {
      let favoritesCount = 0;
      this.favoritesList.forEach((item) => {
        favoritesCount += Number(item.count);
      });
      return favoritesCount;
    },
  },
  methods: {
    // 添加新的收藏夹
    addNewFavorites() {
      // 这一步是展示添加新收藏夹组件
      this.$store.commit("setNeedToShowInDialog", "addFolderBlock");
      // 这一步是隐藏添加新收藏夹不显示返回按钮
      this.$store.commit("setShowAddFolderBlockBackButton", "fromPopover");
      // 打开收藏操作弹窗
      this.$store.commit("setFavoritesDialogStatus", true);
    },
    // 选择操作菜单的选项
    selectThisMenuItem(index, operatingItem, item) {
      switch (index) {
        case 0:
          // 这一步是展示添加新收藏夹组件
          this.$store.commit("setNeedToShowInDialog", "editFolderBlock");
          this.$store.commit("setNeedEditFavoritesInfo", item);
          // 打开收藏操作弹窗
          this.$store.commit("setFavoritesDialogStatus", true);
          break;
        case 1:
          this.$confirm("", "", {
            confirmButtonText: " ",
            cancelButtonText: " ",
            type: "warning",
            center: true,
            iconClass: "iconfont_Me icon-delet-fill",
            customClass: "my-confirm",
            cancelButtonClass: "el-icon-close my-confirm-btn",
            confirmButtonClass: "el-icon-check my-confirm-btn",
          })
            .then(async () => {
              await this.deleteSelectedFavorites(item);
            })
            .catch((e) => {});
          break;
      }
    },
    // 选择这个收藏夹
    selectThisFavorites(index) {
      let params = {
        favoritesName: "",
        bookmarkId: "",
      };
      switch (index) {
        case -1:
          params.favoritesName = this.$t("allCollections");
          break;
        default:
          params.favoritesName = this.favoritesList[index].name;
          params.bookmarkId = this.favoritesList[index].id;
          break;
      }
      this.$emit("selectThisFavorites", params);
    },
    // 删除选中的收藏夹
    async deleteSelectedFavorites(operatingItem) {
      let result = await deleteFavorites(operatingItem.id);
      if (result.code == 200) {
        this.$store.commit("setNeedRefreshFavoritesList", true);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped></style>
<style lang="stylus">
.favoritesPopover
  padding 0 !important;
  .headerIntervalLine
    width 100%;
    height 1px;
    background #F0F0F0;
  .favoritesListContent
    width 100%;
    max-height 300px;
    box-sizing border-box;
    padding 0 20px 10px 20px;
    overflow-x hidden;
    overflow-y auto;
    scrollbar-width: none;
    &::-webkit-scrollbar
      width 4px;
    &::-webkit-scrollbar-thumb
      border-radius: 2px;
      background: rgba(100,100,100,0.5);
      box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
    &::-webkit-scrollbar-track
      border-radius: 2px;
      background: rgba(0,0,0,0.05);
      box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    .favoritesItem
      width 100%;
      height 40px;
      cursor pointer;
      box-sizing border-box;
      border-bottom 1px solid rgba(0,0,0,0.16);
      display flex;
      align-items center;
      justify-content flex-start;
      &:hover
        .favoritesLeft
          .favoritesName
            color #33CC66 !important;
          .favoritesCount
            color #33CC66 !important;
      &:last-child
        border none;
      .favoritesLeft
        flex 1;
        min-width 0;
        height 40px;
        display flex;
        align-items center;
        justify-content flex-start;
        .favoritesName
          max-width 100%;
          font-size 14px;
          color #343434;
          overflow hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        .favoritesCount
          min-width 30px;
          flex-shrink 0
          font-size 12px;
          color #8F8F8F;
          margin-left 8px;
      .favoritesRight
        width 20px;
        height 10px;
        flex-shrink 0;
        cursor pointer;
        display flex;
        align-items center;
        justify-content center;
        img
          width 2px;
          height 10px;
  .noDataContent
    width 100%;
    height 300px;
</style>
