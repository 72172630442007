<template>
  <div class="personalInfoContent">
    <PersonalInformation
      v-show="currentTabKey === 'personalInfo'"
      :personalInfo="personalInfo"
    ></PersonalInformation>
    <GroupInformation
      ref="groupInformation"
      v-show="currentTabKey === 'groupInfo'"
      :currentTabKey="currentTabKey"
      :personalInfo="personalInfo"
    >
    </GroupInformation>
    <PostInformation
      ref="postInformation"
      v-show="currentTabKey === 'postInfo'"
      :currentTabKey="currentTabKey"
      :personalInfo="personalInfo"
    >
    </PostInformation>
    <FavoritesList
      ref="favoritesList"
      v-show="currentTabKey === 'collection'"
      :currentTabKey="currentTabKey"
      :personalInfo="personalInfo"
    >
    </FavoritesList>
    <DeviceInformation
      ref="deviceInformation"
      v-show="currentTabKey === 'deviceInfo'"
      :currentTabKey="currentTabKey"
      :personalInfo="personalInfo"
    ></DeviceInformation>
  </div>
</template>
<script>
import PersonalInformation from "./contentPagesPackage/personalInformation";
import GroupInformation from "./contentPagesPackage/groupInformation";
import PostInformation from "./contentPagesPackage/postInformation";
import FavoritesList from "./contentPagesPackage/favoritesList";
import DeviceInformation from "./contentPagesPackage/deviceInformation";
export default {
  name: "personalInfoContent",
  components: {
    PersonalInformation,
    GroupInformation,
    PostInformation,
    FavoritesList,
    DeviceInformation,
  },
  props: {
    // 用户的详细信息
    personalInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentTabKey: {
      type: String,
      default: "personalInfo",
    },
  },
  methods: {
    // 主要是用让子组件触发加载更多的数据
    async startLoadData() {
      if (this.currentTabKey === "groupInfo") {
        await this.$refs.groupInformation.loadMoreData();
      } else if (this.currentTabKey === "postInfo") {
        await this.$refs.postInformation.loadMoreData();
      } else if (this.currentTabKey === "collection") {
        await this.$refs.favoritesList.loadMoreData();
      } else if (this.currentTabKey === "deviceInfo") {
        await this.$refs.deviceInformation.loadMoreData();
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.personalInfoContent
  width 100%;
  min-height 0;
  overflow hidden;
  margin-top 10px;
</style>
