<template>
  <div class="wrapBox">
    <div
      class="createNewGroupBox flexStyle"
      @click.stop="createNewGroup"
      v-if="personalInfo.providerId === $store.getters.userInfo.id"
    >
      <div class="createButton flexStyle">
        <span class="iconfont_Me icon-plus iconStyle"></span>
      </div>
    </div>
    <GroupInformationItem
      class="createNewGroupBox"
      v-for="item in groupInfoList"
      :key="item.groupId"
      :groupInfo="item"
      :showFooter="false"
      :showPopover="true"
      :operatingMenu="operatingMenu()"
    ></GroupInformationItem>
    <div
      class="noDataBox"
      v-if="
        personalInfo.providerId !== $store.getters.userInfo.id &&
          groupInfoList.length === 0
      "
    >
      <PlaceholderMap :placeholderType="2"></PlaceholderMap>
    </div>
  </div>
</template>
<script>
import GroupInformationItem from "../../../../components/homeDisplayComponents/groupInformationItem";
import { getGroupListData, deleteGroup } from "@/api/newVersion/personalCenter";
import PlaceholderMap from "../../../../components/placeholderMap";
export default {
  name: "groupInformation",
  components: {
    GroupInformationItem,
    PlaceholderMap,
  },
  props: {
    // 用户的详细信息
    personalInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentTabKey: {
      type: String,
      default: "personalInfo",
    },
  },
  watch: {
    // 监听是否需要加载群列表
    currentTabKey: {
      async handler(val, old) {
        if (val === "groupInfo" && val !== old) {
          await this.getGroupData();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      // 请求的页码
      pageNum: 1,
      // 帖子列表数据集合
      groupInfoList: [],
      // 被选中删除的群组
      waitForDeleteGroup: null,
    };
  },
  methods: {
    operatingMenu() {
      let operatingMenu = [
        {
          iconClassName: "icon-write",
          operatingFn: this.editGroup,
          tooltipContent: this.$t("editpost"),
        },
        {
          iconClassName: "icon-delet",
          operatingFn: this.deleteConfirm,
          tooltipContent: this.$t("delete"),
        },
      ];

      return operatingMenu;
    },
    // 确认删除弹窗
    deleteConfirm(item) {
      this.waitForDeleteGroup = item;
      this.confirm(null, null, "el-icon-delete", this.deleteGroup);
    },
    // 删除群组
    async deleteGroup() {
      let params = {
        groupId: this.waitForDeleteGroup.groupId,
        userId: this.$store.getters.userInfo.id,
      };
      let result = await deleteGroup(params);
      if (result) {
        this.$message({
          type: "success",
          message: result.message,
        });
        await this.getGroupData();
      }
    },
    // 编辑群组信息
    async editGroup(item) {
      this.routeJump({
        name: "editCenter",
        params: {
          id: item.groupId,
          type: "editGroup",
        },
      });
    },
    // 创建新群组
    createNewGroup() {
      this.routeJump({
        name: "editCenter",
        params: {
          type: "group",
        },
      });
    },
    // 加载更多数据
    loadMoreData() {
      return new Promise(async (resolve, reject) => {
        this.pageNum++;
        await this.getGroupData();
        resolve("success");
      });
    },
    // 获取数据的方法
    getGroupData() {
      return new Promise(async (resolve, reject) => {
        let params = {
          providerId: this.personalInfo.providerId,
          groupMode: "all",
          page: this.pageNum,
          pageSize: 20,
        };
        let result = await getGroupListData(
          this.personalInfo.providerId,
          params
        );
        if (result.code === 200) {
          if (result.data.data.length !== 0) {
            // 当时请求的第一页的时候，清空列表数据
            if (this.pageNum === 1) {
              this.groupInfoList = [];
            }
            this.groupInfoList = this.groupInfoList.concat(result.data.data);
            // 过滤一次,防止有相同的数据出现
            this.groupInfoList = this.filterAnArrayOfObjects(
              this.groupInfoList,
              "groupId"
            );
            // bug[3771] 5.2.3未改
          } else if (this.pageNum === 1) {
            this.groupInfoList = [];
          } else if (this.pageNum > 1) {
            this.pageNum--;
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        resolve("success");
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.wrapBox {
  width: 100%;
  min-height: 0;
  overflow: hidden;

  .noDataBox {
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flexStyle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .createNewGroupBox {
    width: 278px;
    height: 290px;
    background: #FFFFFF;
    display: flex;
    margin: 0 16px 10px 0;
    float: left;

    &:nth-child(3n) {
      margin-right: 0px !important;
    }

    .createButton {
      width: 236px;
      height: 251px;
      cursor: pointer;
      border-radius: 6px;
      box-sizing: border-box;
      border: 1px solid #CCCCCC;

      .iconStyle {
        font-size: 24px;
        color: #33CC66;
      }
    }
  }
}
</style>
