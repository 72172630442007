var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageHeaderBox" }, [
    _c(
      "div",
      {
        staticClass: "headerBackground",
        class: _vm.personalInfo.vipStatus ? "vipBanner" : ""
      },
      [
        !_vm.personalInfo.vipStatus &&
        _vm.personalInfo.providerId === _vm.$store.getters.userInfo.id
          ? _c(
              "div",
              { staticClass: "becomeVipBox", on: { click: _vm.becomeVip } },
              [
                _c(
                  "div",
                  { staticClass: "vipIcon" },
                  [
                    _c("el-image", {
                      staticStyle: { width: "20px", height: "17px" },
                      attrs: {
                        src: require("../../../assets/images/pay/masonry.png"),
                        fit: "fill"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("becomeApaidMember")) + "\n    "
                )
              ]
            )
          : _vm._e(),
        _vm.personalInfo.vipStatus &&
        _vm.personalInfo.providerId === _vm.$store.getters.userInfo.id
          ? _c(
              "div",
              { staticClass: "renewVipBox", on: { click: _vm.renewVip } },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("renewalPaidMember")) + "\n    "
                )
              ]
            )
          : _vm._e()
      ]
    ),
    _c("div", { staticClass: "showPersonalInfo" }, [
      _c(
        "div",
        { staticClass: "personalAvatar" },
        [
          _c("Avatar", {
            attrs: {
              path:
                _vm.personalInfo.isDelete != 2 && _vm.personalInfo.isDelete != 3
                  ? _vm.personalInfo.profilePhoto
                  : "",
              international:
                _vm.personalInfo.country !== ""
                  ? Number(_vm.personalInfo.country)
                  : 0,
              needShowBorder: true,
              isFrozen: _vm.personalInfo.isFrozen
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "firstRowBox" }, [
        _vm.personalInfo.isDelete != 2 && _vm.personalInfo.isDelete != 3
          ? _c(
              "div",
              { staticClass: "actionPostBox" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: _vm.$t("thumbsUp"),
                      placement: "bottom"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.likeLoading,
                            expression: "likeLoading"
                          }
                        ],
                        staticClass: "actionStyle changelikeStatusStyle",
                        style: {
                          background: _vm.personalInfo.likeStatus
                            ? "#FFF6F6"
                            : ""
                        },
                        attrs: {
                          "element-loading-spinner": "el-icon-loading",
                          "element-loading-background": "#FFFFFF"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changelikeStatus.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "iconfont_Me iconStyle",
                          class: _vm.personalInfo.likeStatus
                            ? "icon-fabulous-fill"
                            : "icon-fabulous",
                          style: {
                            color: _vm.personalInfo.likeStatus ? "#FC4C4C" : ""
                          }
                        })
                      ]
                    )
                  ]
                ),
                _c(
                  "SharePopover",
                  {
                    attrs: { shareConfig: _vm.shareConfig },
                    on: { shareForMelinked: _vm.shareForMelinked }
                  },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: this.$t("share"),
                          placement: "bottom"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "actionStyle shareButtonStyle" },
                          [
                            _c("span", {
                              staticClass: "iconfont_Me icon-share iconStyle"
                            })
                          ]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm.operatingMenu.length !== 0 && !_vm.isSelf
                  ? _c(
                      "CommonPopover",
                      {
                        staticClass: "actionStyle otherStyle",
                        attrs: {
                          operatingMenu: _vm.operatingMenu,
                          width: _vm.width,
                          mode: _vm.mode,
                          shareConfig: _vm.shareConfig
                        },
                        on: { selectThisMenuItem: _vm.selectThisMenuItem }
                      },
                      [
                        _c("div", { staticClass: "commonPopoverButton" }, [
                          _c("i", {
                            staticClass: "iconfont_Me icon-a-zu9781",
                            staticStyle: { "font-size": "18px" }
                          })
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "secondRowBox" }, [
        _c("div", { staticClass: "leftBox" }, [
          _vm.personalInfo.isDelete != 2 && _vm.personalInfo.isDelete != 3
            ? _c("div", { staticClass: "personalName" }, [
                _c(
                  "span",
                  {
                    staticClass: "nameSpan",
                    class: _vm.personalInfo.vipStatus ? "vipName" : ""
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.personalInfo.name) +
                        "\n          "
                    )
                  ]
                ),
                _vm.personalInfo.gender && _vm.personalInfo.gender !== ""
                  ? _c("span", {
                      staticClass: "iconfont_Me iconStyle",
                      class:
                        _vm.personalInfo.gender == "female"
                          ? "icon-female-fill"
                          : "icon-male-fill",
                      style: {
                        color:
                          _vm.personalInfo.gender == "female"
                            ? "#F36172"
                            : "#1CA9C7"
                      }
                    })
                  : _vm._e(),
                _vm.personalInfo.thirdAccountType !== 0 &&
                _vm.personalInfo.providerId === _vm.$store.getters.userInfo.id
                  ? _c("span", {
                      staticClass: "iconfont_Me",
                      class: _vm.thirdAccountIcon,
                      style: {
                        color: _vm.thirdAccountStyle,
                        fontSize: "22px",
                        marginLeft: "5px"
                      }
                    })
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.personalInfo.vipStatus
            ? _c(
                "div",
                {
                  staticClass: "personalVip",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleClickVip(_vm.personalInfo.providerId)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "vipIcon" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "20px", height: "17px" },
                        attrs: {
                          src: require("../../../assets/images/pay/masonry.png"),
                          fit: "fill"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vipTxt" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.$t("paidMember")) + " "
                    ),
                    _c("i", { staticClass: "el-icon-arrow-right" })
                  ])
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "personalIdOrTime" }, [
            _c("img", {
              staticClass: "idIcon",
              attrs: {
                src: require("../../../assets/images/newPersonalCenter/idIcon.png")
              }
            }),
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.personalInfo.isDelete != 2 &&
                    _vm.personalInfo.isDelete != 3
                    ? _vm.personalInfo.ucId
                    : _vm.personalInfo.providerId
                ) +
                "\n        "
            )
          ]),
          _c("div", { staticClass: "personalIdOrTime" }, [
            _c("img", {
              staticClass: "idIcon",
              attrs: {
                src: require("../../../assets/images/newPersonalCenter/timeIcon.png")
              }
            }),
            _vm._v(
              "\n          " + _vm._s(_vm.personalInfo.joinTime) + "\n        "
            )
          ])
        ]),
        _vm.personalInfo.isDelete != 2 && _vm.personalInfo.isDelete != 3
          ? _c(
              "div",
              { staticClass: "rightBox" },
              [
                _vm.personalInfo.providerId === _vm.$store.getters.userInfo.id
                  ? _c(
                      "div",
                      {
                        staticClass: "editButton buttonCommonStyle",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.jumpEditPage.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.$t("editpost"),
                              placement: "bottom"
                            }
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont_Me icon-edit1 iconStyle"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: _vm.personalInfo.friendStatus
                        ? _vm.$t("deleteFriends")
                        : _vm.$t("addFrirend"),
                      placement: "bottom"
                    }
                  },
                  [
                    _vm.personalInfo.providerId !==
                    _vm.$store.getters.userInfo.id
                      ? _c(
                          "div",
                          {
                            staticClass: "editButton buttonCommonStyle",
                            style: {
                              background:
                                _vm.blacklistButtonStatus &&
                                !_vm.personalInfo.friendStatus
                                  ? "#B3B3B3"
                                  : "",
                              cursor:
                                _vm.blacklistButtonStatus &&
                                !_vm.personalInfo.friendStatus
                                  ? "not-allowed"
                                  : ""
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.changeFriendStatus.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont_Me iconStyle",
                              class: _vm.personalInfo.friendStatus
                                ? "icon-deleteuser-fill"
                                : "icon-Adduser-fill"
                            })
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: _vm.$t("chat"),
                      placement: "bottom"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "commomButtonHover buttonCommonStyle",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.startChatting.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass:
                            "iconfont_Me icon-information-fill iconStyle",
                          style: { color: "#FFFFFF" }
                        })
                      ]
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }