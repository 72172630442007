<template>
  <div class="wrapBox">
    <div v-if="personalInfo.isDelete != 2 && personalInfo.isDelete != 3">
      <div class="informationDisplayBox">
        <!-- 没有可展示内容的时候 -->
        <div
          class="noDataBox"
          v-if="
            introHtmlText === '' &&
              tags.length === 0 &&
              introArrayData.length === 0 &&
              personalInfo.providerId !== $store.getters.userInfo.id
          "
        >
          <PlaceholderMap :placeholderType="2"></PlaceholderMap>
        </div>
        <!-- 没有填写个人资料时展示的区域 -->
        <div
          class="unfilledBox"
          v-if="
            introHtmlText === '' &&
              tags.length === 0 &&
              introArrayData.length === 0 &&
              personalInfo.providerId === $store.getters.userInfo.id
          "
        >
          <div class="goImprovePersonalInformation" @click.stop="jumpEditPage">
            <span class="iconfont_Me icon-write iconStyle"></span>
          </div>
        </div>
        <!-- 展示个人标签的区域 -->
        <div class="showTagsBox" v-if="tags.length !== 0">
          <Label
            :style="{ minWidth: '90px', marginBottom: '8px' }"
            v-for="(item, index) in tags"
            :labelContent="item"
            :labelIndex="index"
            :key="index + item"
          ></Label>
        </div>
        <!-- 已填写个人资料后展示的区域 -->
        <div
          class="showInfoBox"
          v-if="introHtmlText !== '' || introArrayData.length !== 0"
        >
          <div
            class="showHtmlBox"
            :style="{ paddingTop: `${tags.length !== 0 ? 0 : 35}px` }"
            v-if="needShowHtmlBox"
            v-html="introHtmlText"
          ></div>
          <div
            class="showArrayData"
            :style="{ paddingTop: `${tags.length !== 0 ? 0 : 35}px` }"
            v-else
          >
            <template v-for="(item, index) in introArrayData">
              <div
                class="textItem"
                :key="index"
                v-if="item.key === 'text'"
                v-html="item.content.replace(/\n/g, '<br>')"
              ></div>
              <div class="titleItem" :key="index" v-if="item.key === 'title'">
                {{ item.content }}
              </div>
              <div class="imageItem" :key="index" v-if="item.key === 'image'">
                <el-image
                  style="width: 100%"
                  :src="item.content"
                  :fit="'cover'"
                ></el-image>
              </div>
              <div class="videoItem" :key="index" v-if="item.key === 'video'">
                <video
                  class="videoStyle"
                  controls="controls"
                  type="video/mp4"
                  webkit-playsinline="true"
                  playsinline="true"
                  x-webkit-airplay="allow"
                  x5-video-player-type="h5"
                  x5-video-orientation="portraint"
                  x5-playsinline="true"
                  x5-video-player-fullscreen="true"
                  :src="
                    item.content.indexOf('?vframe/jpg/offset/0') !== -1
                      ? item.content.split('?')[0]
                      : item.content
                  "
                ></video>
              </div>
              <div class="fileItem" :key="index" v-if="item.key === 'file'">
                <FileItem
                  :fileInfo="{
                    path: item.content,
                    name: item.fileName,
                    progress: '100.00',
                  }"
                ></FileItem>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="commentAreaBox">
        <CommentArea
          v-if="personalInfo.providerId"
          :personalInfo="personalInfo"
          :needScroll="true"
        ></CommentArea>
      </div>
    </div>
    <div v-else>
      <div class="noDataBox">
        <PlaceholderMap
          style="margin-top:160px"
          :placeholderType="2"
        ></PlaceholderMap>
      </div>
    </div>
  </div>
</template>
<script>
import CommentArea from "./commentArea";
// 普通附件组件
import Label from "../../../../components/label";
import FileItem from "../../../../components/showFileItem";
import PlaceholderMap from "../../../../components/placeholderMap";
export default {
  name: "personalInformation",
  components: {
    CommentArea,
    Label,
    FileItem,
    PlaceholderMap,
  },
  props: {
    // 个人资料信息
    personalInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    // 监听用户信息变化
    personalInfo: {
      handler(val, old) {
        if (val && val !== old && val.providerId) {
          // 重置所有数据
          Object.assign(this.$data, this.$options.data.call(this));
          this.handlerData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      // 个人简介文本
      introHtmlText: "",
      // 个人简介数组
      introArrayData: [],
      // 个人标签
      tags: [],
    };
  },
  computed: {
    // 展示html窗口
    needShowHtmlBox() {
      return this.introHtmlText !== "";
    },
  },
  methods: {
    // 处理个人中心的简介数据
    handlerData() {
      if (this.personalInfo.skills && this.personalInfo.skills !== "") {
        this.tags = this.personalInfo.skills.split(",");
      }
      // 这里判断是因为要防止后台处理的过的富文本内容和客户端内容可能会一样，默认取客户端内容
      if (
        this.personalInfo.richIntro === this.personalInfo.intro &&
        this.personalInfo.intro &&
        this.personalInfo.intro !== ""
      ) {
        try {
          this.introArrayData = JSON.parse(
            this.personalInfo.intro.replace(/\n/g, "")
          );
        } catch (error) {
          console.log(error);
        }
      } else if (
        this.personalInfo.richIntro !== "" &&
        this.personalInfo.richIntro
          .replace(/<.*?>/g, "")
          .replace(/&lt;.*?&gt;/g, "")
          .replace(/ /g, "") !== ""
      ) {
        this.introHtmlText = this.personalInfo.richIntro;
      } else if (this.personalInfo.intro !== "") {
        try {
          this.introArrayData = JSON.parse(
            this.personalInfo.intro.replace(/\n/g, "")
          );
        } catch (error) {
          console.log(error);
        }
      }
      this.$forceUpdate();
    },
    // 跳到编辑页面
    jumpEditPage() {
      this.routeJump({
        name: "editCenter",
        params: {
          id: this.personalInfo.providerId,
          type: "personal",
        },
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.wrapBox {
  width: 100%;
  min-height: 0;
  overflow: hidden;

  .informationDisplayBox {
    width: 100%;
    overflow: hidden;
    background: #FFFFFF;
    margin-bottom: 10px;

    .noDataBox {
      padding: 100px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .unfilledBox {
      width: 100%;
      height: 240px;
      display: flex;
      align-items: center;
      flex-flow: column;
      justify-content: center;
      font-size: 14px;
      color: #8F8F8F;
      user-select: none;

      .goImprovePersonalInformation {
        margin-top: 20px;
        width: 100px;
        height: 30px;
        box-sizing: border-box;
        border: 1px solid #33CC66;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: #F2FCF8;
        }

        .iconStyle {
          color: #33CC66;
          font-size: 18px;
        }
      }
    }

    .showTagsBox {
      width: 100%;
      overflow: hidden;
      padding: 35px 35px 10px 35px;
    }

    .showInfoBox {
      width: 100%;
      overflow: hidden;

      .showHtmlBox {
        width: 100%;
        padding: 35px;
        overflow: hidden;
        box-sizing: border-box;
        white-space: pre-wrap;
      }

      .showArrayData {
        width: 100%;
        padding: 35px 35px 25px 35px;
        overflow: hidden;
        box-sizing: border-box;

        .textItem {
          width: 100%;
          font-size: 14px;
          line-height: 24px;
          color: #343434;
          white-space: pre-wrap;
        }

        .titleItem {
          width: 100%;
          font-size: 14px;
          font-weight: bold;
          line-height: 24px;
          color: #343434;
          white-space: pre-wrap;
        }

        .imageItem {
          width: 60%;
          height: auto;
        }

        .videoItem {
          height: 350px;

          .videoStyle {
            height: 100%;
          }
        }

        .fileItem {
          width: 100%;
        }
      }
    }
  }

  .commentAreaBox {
    width: 100%;
    padding: 20px 30px;
    box-sizing: border-box;
    background: #FFFFFF;
  }
}
</style>
<style lang="stylus">
@import './personalInformation';

.informationDisplayBox {
  .showInfoBox {
    .showHtmlBox {
      font-size: 14px;
      line-height: 24px;
      color: #343434;

      img {
        max-width: 60% !important;
        height: auto !important;
      }

      video {
        max-height: 350px !important;
      }
    }
  }
}
</style>
