<template>
  <div class="tabbarBox">
    <div class="firstRow">
      <div class="tabbarItem" v-for="(item, index) in handleTabbarParam" :key="index"
        @click.stop="selectThisTabItem(index)">
        <div class="innerTabBox">
          <el-tooltip class="item" effect="dark" :content="item.tooltipContent" placement="bottom">
            <span v-if="item.className && item.className !== ''" class="iconfont_Me iconStyle" :class="[item.className]"
              :style="{ color: item.isSelected ? '#343434' : '#8F8F8F' }"></span>
          </el-tooltip>
          <div v-if="item.selectedImageSrc && item.unSelectedImageSrc"
            :style="{ width: `${item.width}px`, height: `${item.height}px` }">
            <el-tooltip class="item" effect="dark" :content="item.tooltipContent" placement="bottom">
              <img :style="{ width: `100%`, height: `100%` }"
                :src="item.isSelected ? item.selectedImageSrc : item.unSelectedImageSrc">
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="secondRow">
      <div class="selectedShowLine" :style="{ left: `${(selectIndex + 1) * 25 + selectIndex * 85}px` }"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomTabbar",
  props: {
    tabbarParam: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  watch: {
    // 监听数组列表变化
    tabbarParam: {
      handler(val, old) {
        this.handleTabbarParam = val;
      },
      deep: true
    }
  },
  data() {
    return {
      handleTabbarParam: [],
    }
  },
  computed: {
    selectIndex() {
      let selectIndex = this.handleTabbarParam.findIndex(item => item.isSelected === true)
      return selectIndex
    }
  },
  methods: {
    // 选择这个标签选项
    selectThisTabItem(index) {
      this.$emit("selectThisTabIndex", index)
    }
  }
}
</script>
<style lang="stylus" scoped>
  .tabbarBox
    width 100%
    height 100%;
    background transparent;
    display flex;
    flex-flow column;
    align-items center;
    justify-content flex-start;
    .firstRow
      flex 1;
      width 100%;
      min-height 0;
      .tabbarItem
        width 110px;
        height 100%;
        cursor pointer;
        float left;
        display flex;
        flex-flow column;
        align-items center;
        justify-content center;
        .innerTabBox
          width 60px;
          height 100%;
          box-sizing border-box;
          display flex;
          align-items center;
          justify-content center;
          .iconStyle
            font-size 26px;
    .secondRow
      height 2px;
      width 100%;
      flex-shrink 0;
      position relative;
      .selectedShowLine
        top 0;
        left 0;
        width 60px;
        height 100%;
        position absolute;
        background #343434;
        transition left 0.2s;

</style>