var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapBox" },
    [
      _vm.personalInfo.providerId === _vm.$store.getters.userInfo.id
        ? _c(
            "div",
            {
              staticClass: "createNewGroupBox flexStyle",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.createNewGroup.apply(null, arguments)
                }
              }
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm._l(_vm.groupInfoList, function(item) {
        return _c("GroupInformationItem", {
          key: item.groupId,
          staticClass: "createNewGroupBox",
          attrs: {
            groupInfo: item,
            showFooter: false,
            showPopover: true,
            operatingMenu: _vm.operatingMenu()
          }
        })
      }),
      _vm.personalInfo.providerId !== _vm.$store.getters.userInfo.id &&
      _vm.groupInfoList.length === 0
        ? _c(
            "div",
            { staticClass: "noDataBox" },
            [_c("PlaceholderMap", { attrs: { placeholderType: 2 } })],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "createButton flexStyle" }, [
      _c("span", { staticClass: "iconfont_Me icon-plus iconStyle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }