var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "infinite-scroll",
          rawName: "v-infinite-scroll",
          value: _vm.startLoadData,
          expression: "startLoadData"
        }
      ],
      staticClass: "centerPage",
      attrs: {
        "infinite-scroll-disabled": "loadDataDisabled",
        "infinite-scroll-distance": "10",
        "infinite-scroll-immediate-check": "false"
      }
    },
    [
      _c(
        "div",
        { staticClass: "centerPageContent" },
        [
          _c("PersonalCenterHeader", {
            attrs: {
              personalInfo: _vm.personalInfo,
              blacklistInfo: _vm.blacklistInfo
            },
            on: { refreshPersonalInfo: _vm.getPersonalInfoById }
          }),
          _c("div", { staticClass: "contentBottom" }, [
            _c("div", { staticClass: "leftSidebar" }, [
              _c(
                "div",
                { staticClass: "tabbarOuter" },
                [
                  _c("CustomTabbar", {
                    attrs: { tabbarParam: _vm.tabbarParam },
                    on: { selectThisTabIndex: _vm.selectThisTabIndex }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "infoContent" },
                [
                  _c("PersonalInfoContent", {
                    ref: "personalInfoContent",
                    attrs: {
                      personalInfo: _vm.personalInfo,
                      currentTabKey: _vm.currentTabKey
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "rightSidebar" },
              [
                !_vm.personalInfo.hasPwd &&
                _vm.personalInfo.providerId === _vm.$store.getters.userInfo.id
                  ? _c("div", { staticClass: "thirdPartyLoginTips" }, [
                      _c("div", { staticClass: "tipsText" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("thirdPartyLoginTips")) +
                            "\n          "
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "goToSetPassword",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.thirdAccountToSetPassword.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _c("img", {
                            style: { width: "15px", height: "17px" },
                            attrs: {
                              src: require("../../../assets/images/newPersonalCenter/goToSetPasswordIcon.png"),
                              alt: ""
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _c("MelinkedTips")
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }