var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapBox" }, [
    _vm.personalInfo.isDelete != 2 && _vm.personalInfo.isDelete != 3
      ? _c("div", [
          _c("div", { staticClass: "informationDisplayBox" }, [
            _vm.introHtmlText === "" &&
            _vm.tags.length === 0 &&
            _vm.introArrayData.length === 0 &&
            _vm.personalInfo.providerId !== _vm.$store.getters.userInfo.id
              ? _c(
                  "div",
                  { staticClass: "noDataBox" },
                  [_c("PlaceholderMap", { attrs: { placeholderType: 2 } })],
                  1
                )
              : _vm._e(),
            _vm.introHtmlText === "" &&
            _vm.tags.length === 0 &&
            _vm.introArrayData.length === 0 &&
            _vm.personalInfo.providerId === _vm.$store.getters.userInfo.id
              ? _c("div", { staticClass: "unfilledBox" }, [
                  _c(
                    "div",
                    {
                      staticClass: "goImprovePersonalInformation",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.jumpEditPage.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "iconfont_Me icon-write iconStyle"
                      })
                    ]
                  )
                ])
              : _vm._e(),
            _vm.tags.length !== 0
              ? _c(
                  "div",
                  { staticClass: "showTagsBox" },
                  _vm._l(_vm.tags, function(item, index) {
                    return _c("Label", {
                      key: index + item,
                      style: { minWidth: "90px", marginBottom: "8px" },
                      attrs: { labelContent: item, labelIndex: index }
                    })
                  }),
                  1
                )
              : _vm._e(),
            _vm.introHtmlText !== "" || _vm.introArrayData.length !== 0
              ? _c("div", { staticClass: "showInfoBox" }, [
                  _vm.needShowHtmlBox
                    ? _c("div", {
                        staticClass: "showHtmlBox",
                        style: {
                          paddingTop: `${_vm.tags.length !== 0 ? 0 : 35}px`
                        },
                        domProps: { innerHTML: _vm._s(_vm.introHtmlText) }
                      })
                    : _c(
                        "div",
                        {
                          staticClass: "showArrayData",
                          style: {
                            paddingTop: `${_vm.tags.length !== 0 ? 0 : 35}px`
                          }
                        },
                        [
                          _vm._l(_vm.introArrayData, function(item, index) {
                            return [
                              item.key === "text"
                                ? _c("div", {
                                    key: index,
                                    staticClass: "textItem",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        item.content.replace(/\n/g, "<br>")
                                      )
                                    }
                                  })
                                : _vm._e(),
                              item.key === "title"
                                ? _c(
                                    "div",
                                    { key: index, staticClass: "titleItem" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.content) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.key === "image"
                                ? _c(
                                    "div",
                                    { key: index, staticClass: "imageItem" },
                                    [
                                      _c("el-image", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          src: item.content,
                                          fit: "cover"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.key === "video"
                                ? _c(
                                    "div",
                                    { key: index, staticClass: "videoItem" },
                                    [
                                      _c("video", {
                                        staticClass: "videoStyle",
                                        attrs: {
                                          controls: "controls",
                                          type: "video/mp4",
                                          "webkit-playsinline": "true",
                                          playsinline: "true",
                                          "x-webkit-airplay": "allow",
                                          "x5-video-player-type": "h5",
                                          "x5-video-orientation": "portraint",
                                          "x5-playsinline": "true",
                                          "x5-video-player-fullscreen": "true",
                                          src:
                                            item.content.indexOf(
                                              "?vframe/jpg/offset/0"
                                            ) !== -1
                                              ? item.content.split("?")[0]
                                              : item.content
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              item.key === "file"
                                ? _c(
                                    "div",
                                    { key: index, staticClass: "fileItem" },
                                    [
                                      _c("FileItem", {
                                        attrs: {
                                          fileInfo: {
                                            path: item.content,
                                            name: item.fileName,
                                            progress: "100.00"
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "commentAreaBox" },
            [
              _vm.personalInfo.providerId
                ? _c("CommentArea", {
                    attrs: { personalInfo: _vm.personalInfo, needScroll: true }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "noDataBox" },
            [
              _c("PlaceholderMap", {
                staticStyle: { "margin-top": "160px" },
                attrs: { placeholderType: 2 }
              })
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }