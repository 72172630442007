var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.dataLoading,
          expression: "dataLoading"
        }
      ],
      staticClass: "wrapBox"
    },
    [
      _vm.showSelection
        ? _c("PostTypeSelection", {
            style: {
              height: "50px",
              opacity: "1",
              overflow: "visible"
            },
            attrs: { setDefault: _vm.typeSelection },
            on: { confirm: _vm.selectedType }
          })
        : _vm._e(),
      _vm.postInfoList.length !== 0
        ? _vm._l(_vm.postInfoList, function(postInfo, index) {
            return _c(
              "div",
              {
                key: postInfo.gigId + index,
                staticClass: "postInformationItem"
              },
              [
                _c("PostInformationItem", {
                  attrs: {
                    postInfo: postInfo,
                    operatingMenu: _vm.operatingMenu(postInfo),
                    fromType: "self"
                  }
                })
              ],
              1
            )
          })
        : _vm._e(),
      _vm.postInfoList.length === 0 && !_vm.dataLoading
        ? _c(
            "div",
            { staticClass: "noDataBox" },
            [_c("PlaceholderMap", { attrs: { placeholderType: 2 } })],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }